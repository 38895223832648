import React from 'react'
import './Footer.css'
import logo_footer from '../Assets/logo_footer.png'

export const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-content'>
        <div className='footer-logo'>
          <img src={logo_footer} alt='Logo SIPBIBU' />
        </div>
        <div className='footer-sections'>
          <div className='footer-information'>
            <h4>Information</h4>
            <ul>
              <li><a href='/About_Us'>Tentang Kami</a></li>
            </ul>
          </div>
          <div className='footer-services'>
            <h4>Our Services</h4>
            <ul>
              <li><a href='/PilihanQuiz'>Tes Kuesioner</a></li>
              <li><a href='/Terapis'>Kosultasi Online</a></li>
              <li><a href='/Thread'>Forum Diskusi Ibu</a></li>
              <li><a href='/Rubrik'>Rubrik's Mom</a></li>
            </ul>
          </div>
          <div className='footer-contact'>
            <h4><i class="fa-solid fa-phone"></i> Contact Us</h4>
            <p>Phone : +62 857-2760-9498</p>
            <p>Email : sipbibu2024@gmail.com</p>
            <div className='footer-social-media'>
              <a href='https://www.instagram.com/sipbibu/' target='_blank' rel='noopener noreferrer'>
                <i className='fa-brands fa-instagram'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-copyright'>
        <hr />
        <p>© 2024 SIPBIBU - Hak Cipta Dilindungi</p>
      </div>
    </div>
  )
}

export default Footer
