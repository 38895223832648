import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Profile.css';

export const Profile = () => {
    const [userData, setUserData] = useState({
        id: '',
        nama: '',
        no_telp: '',
        alamat: '',
        usia: '',
        email: '',
        avatar: null
    });
    const [quiz1Result, setQuiz1Result] = useState(null);
    const [quiz2Result, setQuiz2Result] = useState(null);
    const [antepartumResult, setAntepartumResult] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const userId = localStorage.getItem('id');
                if (!userId) {
                    console.error('User ID not found in local storage');
                    return;
                }

                // Fetch user profile data
                const userDataResponse = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${userId}`);
                setUserData(userDataResponse.data);

                // Fetch latest quiz results
                const [quiz1Response, quiz2Response, antepartumResponse] = await Promise.all([
                    axios.get(`https://sipbibu.tifpsdku.com/backend/public/getHasilByUserIdLatest/${userId}`).catch(() => null),
                    axios.get(`https://sipbibu.tifpsdku.com/backend/public/getHasilByUserIdLatest_2/${userId}`).catch(() => null),
                    axios.get(`https://sipbibu.tifpsdku.com/backend/public/getHasilByUserIdAnteprtumLatest/${userId}`).catch(() => null)
                ]);

                // Set only the latest result for each type of quiz
                if (quiz1Response && quiz1Response.data.length > 0) {
                    setQuiz1Result(quiz1Response.data[0]);
                }
                if (quiz2Response && quiz2Response.data.length > 0) {
                    setQuiz2Result(quiz2Response.data[0]);
                }
                if (antepartumResponse && antepartumResponse.data.length > 0) {
                    setAntepartumResult(antepartumResponse.data[0]);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const handleAvatarChange = (e) => {
        setAvatarFile(e.target.files[0]);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("id", userData.id);
            formData.append("nama", userData.nama);
            formData.append("no_telp", userData.no_telp);
            formData.append("alamat", userData.alamat);
            formData.append("usia", userData.usia);
            formData.append("email", userData.email);
            formData.append("avatar", avatarFile);

            await axios.post(`https://sipbibu.tifpsdku.com/backend/public/update-profile/data-ibu/${userData.id}`, formData);

            setIsEditing(false);

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Profile berhasil diubah",
            });

        } catch (error) {
            console.error('Error updating data:', error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Profile gagal diubah",
            });
        }
    };

    const getStatusText = (type, result) => {
        if (type === 'antepartum') {
            if (result.total_skor_internal >= 15 || result.total_skor_eksternal >= 33) {
                return <p style={{ color: 'white', backgroundColor: '#f45921', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Belum Baik</p>;
            } else {
                return <p style={{ color: 'white', backgroundColor: '#2BA45D', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Sudah Baik</p>;
            }
        } else if (type === 'postpartum') {
            if (result.total_skor_internal >= 53 || result.total_skor_eksternal >= 19) {
                return <p style={{ color: 'white', backgroundColor: '#f45921', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Belum Baik</p>;
            } else {
                return <p style={{ color: 'white', backgroundColor: '#2BA45D', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Sudah Baik</p>;
            }
        } else if (type === 'epds') {
            if (result.total_skor > 12) {
                return <p style={{ color: 'white', backgroundColor: '#f45921', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Belum Baik</p>;
            } else {
                return <p style={{ color: 'white', backgroundColor: '#2BA45D', width: '165px', textAlign: 'center', borderRadius: '6px' }}>Status : Sudah Baik</p>;
            }
        }
    };

    return (
        <div className="profile-page">
            <div className="profile-container">
                <div className="profile-card">
                    <div className="profile-image">
                        {userData.avatar && <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${userData.avatar}`} alt="Avatar" />}
                    </div>
                    <div className="profile-details">
                        <h2>{userData.nama}</h2>
                        <p>{userData.no_telp}</p>
                        <p>{userData.email}</p>
                        <Button onClick={handleEdit} className="edit-button-profile-ibu">Edit your profile</Button>
                    </div>
                </div>
            </div>
            <div className="results-container">
                <div className="results-card">
                    <h3>Riwayat Tes Pengukuran Resiko Baby blues Sebelum Melahirkan</h3>
                    <hr />
                    {antepartumResult ? (
                        <Card className="result-card">
                            <Card.Body>
                                {getStatusText('antepartum', antepartumResult)}
                                <Card.Title>Total Skor Internal : {antepartumResult.total_skor_internal}</Card.Title>
                                <Card.Title>Total Skor Eksternal : {antepartumResult.total_skor_eksternal}</Card.Title>
                                <Card.Text>Kesimpulan Internal : {antepartumResult.hasil_kesimpulan_internal}</Card.Text>
                                <Card.Text>Kesimpulan Eksternal : {antepartumResult.hasil_kesimpulan_eksternal}</Card.Text>
                                <Card.Text>Tes Diambil Pada : {antepartumResult.create_at}</Card.Text>
                                <Link to="/grafikKuis_1" style={{ textDecoration: 'none', color: '#7B68EE', fontSize: '18px', fontWeight: '600', }}>- Lihat Perkembangan -</Link>
                            </Card.Body>
                        </Card>
                    ) : (
                        <h4>❕Anda belum mengambil tes ini.</h4>
                    )}
                </div>
                <div className="results-card">
                    <h3>Riwayat Tes Pengukuran Baby Blues Pasca Melahirkan</h3>
                    <hr />
                    {quiz2Result ? (
                        <Card className="result-card">
                            <Card.Body>
                                {getStatusText('postpartum', quiz2Result)}
                                <Card.Title>Total Skor Internal: {quiz2Result.total_skor_internal}</Card.Title>
                                <Card.Title>Total Skor Eksternal: {quiz2Result.total_skor_eksternal}</Card.Title>
                                <Card.Text>Kesimpulan Internal: {quiz2Result.hasil_kesimpulan_internal}</Card.Text>
                                <Card.Text>Kesimpulan Eksternal: {quiz2Result.hasil_kesimpulan_eksternal}</Card.Text>
                                <Card.Text>Tes Diambil Pada : {quiz2Result.create_at}</Card.Text>
                                <Link to="/grafikKuis_2" style={{ textDecoration: 'none', color: '#7B68EE', fontSize: '18px', fontWeight: '600', }}>- Lihat Perkembangan -</Link>
                            </Card.Body>
                        </Card>
                    ) : (
                        <h4>❕Anda belum mengambil tes ini.</h4>
                    )}
                </div>
                <div className="results-card">
                    <h3>Riwayat Tes Depresi EPDS</h3>
                    <hr />
                    {quiz1Result ? (
                        <Card className="result-card">
                            <Card.Body>
                                {getStatusText('epds', quiz1Result)}
                                <Card.Title>Total Skor: {quiz1Result.total_skor}</Card.Title>
                                <Card.Text>Kesimpulan: {quiz1Result.hasil_kesimpulan}</Card.Text>
                                <Card.Text>Tes Diambil Pada : {quiz1Result.create_at}</Card.Text>
                                <Link to="/grafikKuis_3" style={{ textDecoration: 'none', color: '#7B68EE', fontSize: '18px', fontWeight: '600', }}>- Lihat Perkembangan -</Link>
                            </Card.Body>
                        </Card>
                    ) : (
                        <h4>❕Anda belum mengambil tes ini.</h4>
                    )}
                </div>
            </div>
            <Modal show={isEditing} onHide={handleCancelEdit} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <hr />
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formNama">
                            <Form.Label>Nama</Form.Label>
                            <Form.Control type="text" name="nama" value={userData.nama} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formNoTelp">
                            <Form.Label>No. Telepon</Form.Label>
                            <Form.Control type="text" name="no_telp" value={userData.no_telp} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formAlamat">
                            <Form.Label>Alamat</Form.Label>
                            <Form.Control type="text" name="alamat" value={userData.alamat} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formUsia">
                            <Form.Label>Usia</Form.Label>
                            <Form.Control type="text" name="usia" value={userData.usia} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formAvatar">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" name="avatar" onChange={handleAvatarChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Simpan
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};


export default Profile;
