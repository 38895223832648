import React, { useState, useEffect } from "react";
import "./Register.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Register = () => {
  const [nama, setNama] = useState("");
  const [noTelp, setNoTelp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [usia, setUsia] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [avatar, setAvatar] = useState(null); 

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/beranda');
    }
  }, [navigate]);

  const handleNamaChange = (event) => {
    setNama(event.target.value);
  };

  const handleNoTelpChange = (event) => {
    setNoTelp(event.target.value);
  };

  const handleAlamatChange = (event) => {
    setAlamat(event.target.value);
  };

  const handleUsiaChange = (event) => {
    setUsia(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleAvatarChange = (event) => {
    setAvatar(event.target.files[0]); 
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("nama", nama);
    formData.append("no_telp", noTelp);
    formData.append("alamat", alamat);
    formData.append("usia", usia);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("avatar", avatar); 

    axios
      .post("https://sipbibu.tifpsdku.com/backend/public/api/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.message === 'Registered Successfully') {
          Swal.fire({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
          setNama("");
          setNoTelp("");
          setAlamat("");
          setUsia("");
          setEmail("");
          setPassword("");
          setAvatar(null);
          navigate('/login');
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to register. Please try again later.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="register-container">
      <div className="cover">
        <h1>Register</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nama"
            value={nama}
            onChange={handleNamaChange}
            required
            className="input-field"
          />
          <input
            type="text"
            placeholder="Nomor Telepon"
            value={noTelp}
            onChange={handleNoTelpChange}
            required
            className="input-field"
          />
          <textarea
            placeholder="Alamat"
            value={alamat}
            onChange={handleAlamatChange}
            required
            className="input-field"
          ></textarea>
          <input
            type="number"
            placeholder="Usia"
            value={usia}
            onChange={handleUsiaChange}
            required
            className="input-field"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
            className="input-field"
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="input-field"
            />
            <button
              type="button"
              onClick={handleShowPasswordToggle}
              className="show-password-btn"
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
          <p class="photo-profile-text">Photo Profile</p>
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            required
            className="input-field"
          />
          <button type="submit" className="register-btn">
            Register
          </button>
        </form>
        <p className="login-link">
          Already have an account? <a href="/login">Login</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
