import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Popular.css';
import mediasi_image_4 from '../Assets/mediasi_image_4.png';
import mediasi_image_3 from '../Assets/mediasi_image_3.png';

export const Popular = () => {
  const navigate = useNavigate();

  const handleVideoClick = () => {
    navigate('/isi_video');
  };

  const handleAudioClick = () => {
    navigate('/isi_audio');
  };

  return (
    <div className='popular-beranda'>
      <div className="popular-intro-top">
      <h1>Edukasi Seputar Kesehatan Ibu</h1>
      <p>Jelajahi koleksi video dan audio meditasi untuk menemukan momen ketenangan <br /> di tengah kesibukan Anda</p>
      </div>
      <div className='card-container-popular'>
        <div className="card-popular" onClick={handleVideoClick}>
          <div className="icon-popular"><i className="fa-solid fa-video"></i></div>
          <div className="content-popular">
            <h2><i class="fa-solid fa-quote-left"></i> Video Meditasi</h2>
            <p>Klik untuk tonton video edukasi seputar kesehatan ibu</p>
          </div>
        </div>
        <div className="card-popular" onClick={handleAudioClick}>
          <div className="icon-popular"><i className="fa-solid fa-music"></i></div>
          <div className="content-popular">
            <h2><i class="fa-solid fa-quote-left"></i> Audio Meditasi</h2>
            <p>Klik untuk tonton dan dengarkan audio yang nyaman untuk membuatmu lebih baik</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popular;
