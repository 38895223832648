import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineDashboard, AiOutlineUsergroupAdd, AiOutlineUser } from 'react-icons/ai';
import { FaBook, FaQuestionCircle, FaVolumeUp, FaVideo, FaComment, FaRegStopCircle, FaFlag } from 'react-icons/fa';
import { IoMdHelpCircle } from 'react-icons/io';
import { BsChevronDown } from 'react-icons/bs';
import './styles.css';

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggle = (key) => {
    if (openKeys.includes(key)) {
      setOpenKeys(openKeys.filter((k) => k !== key));
    } else {
      setOpenKeys([...openKeys, key]);
    }
  };

  const renderMenuItem = (item) => {
    const hasChildren = item.children && item.children.length > 0;
    return (
      <li key={item.key} className={hasChildren && openKeys.includes(item.key) ? 'open' : ''}>
        <NavLink to={item.to} className="menu-item" onClick={() => handleToggle(item.key)}>
          <div className="menu-icon-container">
            {item.icon}
          </div>
          <span className="menu-label">{item.label}</span>
          {hasChildren && <BsChevronDown className="dropdown-icon" />}
        </NavLink>
        {hasChildren && (
          <ul>
            {item.children.map((child) => (
              <li key={child.key}>
                <NavLink to={child.to} className="menu-item">
                  <div className="menu-icon-container">
                    {child.icon}
                  </div>
                  <span className="menu-label">{child.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    // <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
    //   <button className="toggle-sidebar-btn" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
    //     {isSidebarOpen ? 'Close' : 'Open'}
    //   </button>
    //   {isSidebarOpen && (
        <aside className="sidebar">
          <div className="sidebar-content">
            <nav>
              <ul>
                <div className='left-top-title'>
                  <h1>Halaman Admin</h1>
                </div>
                {[
                  {
                    key: 'dashboard',
                    to: '/admin',
                    icon: <AiOutlineDashboard className="menu-icon fs-5" />,
                    label: 'Dashboard',
                  },
                  {
                    key: 'data-user',
                    icon: <AiOutlineUsergroupAdd className="menu-icon fs-5" />,
                    label: 'Data User',
                    children: [
                      {
                        key: 'data-admin',
                        to: '/admin/data-admin',
                        icon: <AiOutlineUser className="menu-icon fs-5" />,
                        label: 'Data Admin',
                      },
                      {
                        key: 'data-ibu',
                        to: '/admin/data-ibu',
                        icon: <AiOutlineUser className="menu-icon fs-5" />,
                        label: 'Data Ibu',
                      },
                      {
                        key: 'data-psikolog',
                        to: '/admin/data-psikolog',
                        icon: <AiOutlineUser className="menu-icon fs-5" />,
                        label: 'Data Psikolog',
                      },
                    ],
                  },
                  {
                    key: 'artikel',
                    to: '/admin/artikel',
                    icon: <FaBook className="menu-icon fs-5" />,
                    label: "Rubrik's Mom",
                  },
                  {
                    key: 'kuisioner',
                    icon: <FaQuestionCircle className="menu-icon fs-5" />,
                    label: 'Kuisioner',
                    children: [
                      {
                        key: 'kuisioner1',
                        to: '/admin/kuisioner',
                        icon: <IoMdHelpCircle className="menu-icon fs-5" />,
                        label: 'Tes EPDS',
                      },
                      {
                        key: 'kuisioner2',
                        to: '/admin/kuisioner2',
                        icon: <IoMdHelpCircle className="menu-icon fs-5" />,
                        label: 'Tes Pasca Lahir',
                      },
                      {
                        key: 'kuisionerantepartum',
                        to: '/admin/kuisionerantepartum',
                        icon: <IoMdHelpCircle className="menu-icon fs-5" />,
                        label: 'Tes Sebelum Lahir',
                      },
                    ],
                  },
                  {
                    key: 'data-thread',
                    to: '/admin/data-thread',
                    icon: <FaComment className="menu-icon fs-5" />,
                    label: 'Forum',
                  },
                  {
                    key: 'report',
                    to: '/admin/report',
                    icon: <FaFlag className="menu-icon fs-5" />,
                    label: 'Report',
                  },
                  {
                    key: 'audio',
                    to: '/admin/audio',
                    icon: <FaVolumeUp className="menu-icon fs-5" />,
                    label: 'Audio',
                  },
                  {
                    key: 'video',
                    to: '/admin/video',
                    icon: <FaVideo className="menu-icon fs-5" />,
                    label: 'Video',
                  },
                ].map(renderMenuItem)}
              </ul>
            </nav>
          </div>
        </aside>
      )}
//     </div>
//   );
// };

export default Sidebar;
