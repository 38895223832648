import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Rubrik.css';

export const Rubrik = () => {
  const [artikelList, setArtikelList] = useState([]);

  useEffect(() => {
    fetch('https://sipbibu.tifpsdku.com/backend/public/artikel')
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          setArtikelList(data.data_artikel);
        } else {
          console.error('Failed to fetch artikel data');
        }
      })
      .catch(error => {
        console.error('Error fetching artikel data:', error);
      });
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="container-rubrik">
      <div className="container-rubrik-intro">
      <h1>Temukan artikel menarik dan bermanfaat</h1>
      <p>"Kami menyediakan banyak sekali artikel loh! Buruan jelajahi fitur Rubrik's Mom"</p>
      </div>
      <div className="row-rubrik">
        {artikelList.map(artikel => (
          <div className="col-rubrik" key={artikel.id}>
            <Link to={`/Isi_Rubrik/${artikel.id}`} className="card-link-rubrik">
              <div className="card-rubrik">
                <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${artikel.cover}`} alt="cover" className="card-img-rubrik" />
                <div className="card-body-rubrik">
                  <h5 className="card-title-rubrik">{artikel.kategori}</h5>
                  <p className="card-subtitle-rubrik">{artikel.judul}</p>
                  <p className="card-text-rubrik">{truncateText(artikel.isi, 100)}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rubrik;
