import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { IoIosNotifications } from "react-icons/io";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import './styles.css';

const Header = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [namaAdmin, setNamaAdmin] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("useEffect dijalankan!"); // Tambahkan log ini
    const loggedInUser = localStorage.getItem("tokenAdmin"); 
    console.log('loggedInUser:', loggedInUser); 

    if (loggedInUser && localStorage.getItem("userTypeAdmin") === "admin") {
      const nama = localStorage.getItem("namaAdmin");
      console.log('nama:', nama);
      setNamaAdmin(nama);
    } else {
      setNamaAdmin(""); 
      navigate("/login");
    }
  }, [location.pathname]);

  const handleLogoutAdmin = () => {
    Swal.fire({
      title: 'Logout',
      text: 'Apakah Anda yakin ingin logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya, Logout',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Berhasil!',
          'Anda telah berhasil logout.',
          'success'
        ).then(() => {
          localStorage.removeItem("tokenAdmin");
          localStorage.removeItem("namaAdmin");
          localStorage.removeItem("userTypeAdmin");
          setNamaAdmin(""); 
          navigate("/login");
        });
      }
    });
  };

  return (
    <header className="d-flex justify-content-between ps-1 pe-5" style={{ background: '#0056b3', padding: '0 16px 31px' }}>
      {React.createElement(
        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: () => setCollapsed(!collapsed),
        }
      )}
      <div className="d-flex gap-4 align-items-center">
        <div className="d-flex gap-3 align-items-center dropdown">
          <div>
            {/* <img
              width={32}
              height={32}
              src="https://stroyka-admin.html.themeforest.scompiler.ru/variants/ltr/images/customers/customer-4-64x64.jpg"
              alt=""
            /> */}
          </div>
          <div
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <h5 className="mb-0">Hi Admin! | {namaAdmin}</h5> {/* Tampilkan nama admin */}
          </div>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <Link
                className="dropdown-item py-1 mb-1"
                style={{ height: "auto", lineHeight: "20px" }}
                to="/"
              >
                View Profile
              </Link>
            </li>
            <li>
              <button
                className="dropdown-item py-1 mb-1"
                style={{ height: "auto", lineHeight: "20px" }}
                onClick={handleLogoutAdmin}
              >
                Signout
              </button>
            </li>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
