import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import './GrafikKuis_1.css';

const GrafikKuis_1 = () => {
    const [data, setData] = useState([]);
    const [lastTest, setLastTest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('id'); // Mengambil userId dari localStorage
                if (!userId) {
                    console.error('User ID not found in localStorage');
                    return;
                }

                const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/getHasilByUserIdAnteprtum/${userId}`);
                const result = response.data.map(item => ({
                    date: new Date(item.create_at).toLocaleDateString(),
                    total_skor_internal: item.total_skor_internal,
                    total_skor_eksternal: item.total_skor_eksternal,
                }));
                setData(result);
                setLastTest(result[result.length - 1]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const getLastTestStatus = () => {
        if (!lastTest) return null;

        const { total_skor_internal, total_skor_eksternal } = lastTest;
        if (total_skor_internal >= 15 && total_skor_eksternal >= 33) {
            return {
                color: '#FF4545',
                text: 'Perkembangan mental anda belum baik 😓'
            };
        } else if (total_skor_internal < 15 && total_skor_eksternal >= 33) {
            return {
                color: '#FF4545',
                text: 'Perkembangan kesehatan mental pada diri anda sudah baik tetapi anda kurang mendapatkan dukungan sosial dari sekitar anda 😌'
            };
        } else if (total_skor_internal >= 15 && total_skor_eksternal < 33) {
            return {
                color: '#FF4545',
                text: 'Perkembangan kesehatan mental pada diri anda belum baik tetapi anda sudah mendapatkan dukungan sosial yang sudah baik 😔'
            };
        } else if (total_skor_internal < 15 && total_skor_eksternal < 33) {
            return {
                color: '#2BA45D',
                text: 'Perkembangan kesehatan mental anda sudah baik dan anda sudah mendapatkan dukungan sosial yang baik. Bagus Sekali 😊👍'
            };
        }
        return null;
    };

    const lastTestStatus = getLastTestStatus();

    return (
        <div className="grafik-kuis-container-satu">
            <Card title="Perkembangan Tes Pengukuran Risiko Baby blues Sebelum Melahirkan" className="grafik-card">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total_skor_internal" fill="#8884d8" />
                        <Bar dataKey="total_skor_eksternal" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
            <Row gutter={16}>
                <Col span={12}>
                    <Card
                        title={<span style={{ color: 'white' }}><i className="fa-solid fa-chart-column"></i> Status Perkembangan Mental Anda</span>}
                        style={{
                            backgroundColor: lastTestStatus ? lastTestStatus.color : 'white',
                            color: 'white',
                            fontFamily: 'poppins',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                    >
                        {lastTestStatus ? lastTestStatus.text : 'Loading...'}
                    </Card>
                    <Card
                        title={<span style={{ color: 'white' }}><i className="fa-solid fa-circle-exclamation"></i> Peringatan Penting</span>}
                        style={{
                            backgroundColor : '#F46262',
                            marginTop: '16px',
                            fontFamily: 'poppins',
                            color: 'white',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                    >
                        <p>Fitur kuisioner ini dirancang untuk membantu mengidentifikasi risiko seorang ibu akan mengalami sindrom baby blues. Hasil dari kuisioner ini bukan merupakan diagnosis medis.</p>
                        <p>Jika Anda merasa tidak yakin dengan hasil kuisioner ini atau mengalami gejala yang parah, kami sangat menyarankan Anda untuk segera menghubungi ahli kesehatan atau rumah sakit terdekat ( NOMOR DARURAT 112 ).</p>
                        <p>Kesehatan Anda sangat penting, dan mendapatkan bantuan profesional adalah langkah terbaik yang bisa Anda ambil untuk memastikan kesejahteraan Anda dan bayi Anda.</p>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title="Informasi Tes"
                        style={{
                            fontFamily: 'poppins',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                    >
                        <p>Tes Pengukuran Resiko Baby Blues Sebelum Melahirkan ini digunakan untuk mengidentifikasi apakah seorang ibu berisiko mengalami sindrom baby blues, dengan menunjukan hasil total skor internal dan total skor eksternal </p>
                        <p>Total skor internal : Penilaian skor ini berdasarkan faktor-faktor dari diri ibu sendiri seperti pikiran, kesehatan, atau mental yang mempengaruhi ibu</p>
                        <p>Total skor eksternal : Penilaian skor ini berdasarkan faktor-faktor sosial dari luar seperti lingkungan, keluarga atau orangtua yang ada disekitar ibu</p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default GrafikKuis_1;
