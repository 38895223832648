import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Artikel.css';

export const Artikel = () => {
  const [artikelList, setArtikelList] = useState([]);
  const [randomArtikel, setRandomArtikel] = useState([]);

  useEffect(() => {
    fetch('https://sipbibu.tifpsdku.com/backend/public/artikel')
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          setArtikelList(data.data_artikel);
          const randomArtikel = getRandomArticles(data.data_artikel, 3);
          setRandomArtikel(randomArtikel);
        } else {
          console.error('Failed to fetch artikel data');
        }
      })
      .catch(error => {
        console.error('Error fetching artikel data:', error);
      });
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const getRandomArticles = (articles, count) => {
    const shuffled = articles.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  return (
    <div className="container-rubrik">
      <div className="container-rubrik-top">
        <h1>Berita dan Tips Seputar Ibu</h1>
        <p>Jelajahi artikel dan temukan strategi pencegahan, perawatan, dan aspek kesehatan lainnya</p>
      </div>
      <div className="row-rubrik">
        {randomArtikel.map(artikel => (
          <div className="col-rubrik" key={artikel.id}>
            <Link to={`/Isi_Rubrik/${artikel.id}`} className="card-link-rubrik">
              <div className="card-rubrik">
                <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${artikel.cover}`} alt="cover" className="card-img-rubrik" />
                <div className="card-body-rubrik">
                  <h5 className="card-title-rubrik">{artikel.kategori}</h5>
                  <p className="card-subtitle-rubrik">{artikel.judul}</p>
                  <p className="card-text-rubrik">{truncateText(artikel.isi, 100)}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Artikel;
