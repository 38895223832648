import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Video = () => {
    const [id, setId] = useState("");
    const [judul, setJudul] = useState("");
    const [deskripsi, setDeskripsi] = useState("");
    const [sumber, setSumber] = useState("");
    const [videoTerapis, setVideoTerapis] = useState(null);
    const [idKategoriVideo, setIdKategoriVideo] = useState("");
    const [kategoriVideo, setKategoriVideo] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataVideo, setDataVideo] = useState([]);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const navigate = useNavigate();

    // Fetch data video
    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/video/getNotRandom");
            setDataVideo(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch kategori video
    const fetchKategoriVideo = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/kategori-video");
            setKategoriVideo(response.data);
        } catch (error) {
            console.error("Error fetching kategori video:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchKategoriVideo();
    }, []);

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("judul", judul);
            formData.append("deskripsi", deskripsi);
            formData.append("sumber", sumber);
            formData.append("id_kategori_video", idKategoriVideo);
            if (videoTerapis !== null) {
                formData.append("video_terapis", videoTerapis);
            }

            await axios.post(`https://sipbibu.tifpsdku.com/backend/public/video/ubah/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            navigate("/admin/video");

            setSuccessAlert(true);
            setSuccessMessage("Data berhasil diubah.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseUpdate();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat mengubah data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (judul === "" || deskripsi === "" || sumber === "" || !videoTerapis || idKategoriVideo === "") {
            alert("Data gagal ditambahkan, field tidak boleh ada yang kosong");
        } else {
            try {
                const formData = new FormData();
                formData.append("judul", judul);
                formData.append("deskripsi", deskripsi);
                formData.append("sumber", sumber);
                formData.append("video_terapis", videoTerapis);
                formData.append("id_kategori_video", idKategoriVideo);

                await axios.post("https://sipbibu.tifpsdku.com/backend/public/video", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                setSuccessAlert(true);
                setSuccessMessage("Data berhasil ditambahkan.");
                setTimeout(() => {
                    setSuccessAlert(false);
                    setSuccessMessage("");
                }, 3000);
                handleCloseCreate();
                fetchData();
            } catch (error) {
                setFailureAlert(true);
                setFailureMessage("Terjadi kesalahan saat menambahkan data.");
                setTimeout(() => {
                    setFailureAlert(false);
                    setFailureMessage("");
                }, 3000);
                console.error("Error:", error);
            }
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/video/${id}`);
            fetchData();
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleShowUpdate = (data) => {
        setId(data.id);
        setJudul(data.judul);
        setDeskripsi(data.deskripsi);
        setSumber(data.sumber);
        setIdKategoriVideo(data.id_kategori_video);
        setShowUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setId("");
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setIdKategoriVideo("");
        setShowUpdateModal(false);
    };

    const handleShowCreate = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreate = () => {
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setVideoTerapis(null);
        setIdKategoriVideo("");
        setShowCreateModal(false);
    };

    const handleShowDelete = (data) => {
        setId(data.id);
        setJudul(data.judul);
        setDeskripsi(data.deskripsi);
        setSumber(data.sumber);
        setVideoTerapis(data.video_terapis);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setId("");
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setVideoTerapis(null);
        setShowDeleteModal(false);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Judul",
            dataIndex: "judul",
            key: "judul",
        },
        {
            title: "Deskripsi",
            dataIndex: "deskripsi",
            key: "deskripsi",
        },
        {
            title: "Sumber",
            dataIndex: "sumber",
            key: "sumber",
        },
        {
            title: "Kategori Video",
            dataIndex: "id_kategori_video",
            key: "id_kategori_video",
            render: (id_kategori_video) => {
                const category = kategoriVideo.find(cat => cat.id === id_kategori_video);
                return category ? category.kategori : "Unknown";
            },
        },
        {
            title: "Video Terapis",
            dataIndex: "video_terapis",
            key: "video_terapis",
            render: (video_terapis) => (
                <video width="320" height="240" controls>
                    <source
                        src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${video_terapis}`}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            ),
        },
        {
            title: "Aksi",
            key: "action",
            render: (text, record) => (
                <span>
                    <Button
                        className="btn btn-warning text-black me-2"
                        style={{ backgroundColor: 'yellow', borderColor: 'yellow', color: 'black', height: '45px', marginBottom: '10px' }}
                        onClick={() => handleShowUpdate(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="btn btn-danger text-white"
                        style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                        onClick={() => handleShowDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ];

    const handleJudulChange = (e) => {
        setJudul(e.target.value);
    };

    const handleDeskripsiChange = (e) => {
        setDeskripsi(e.target.value);
    };

    const handleSumberChange = (e) => {
        setSumber(e.target.value);
    };

    const handleVideoTerapisChange = (e) => {
        setVideoTerapis(e.target.files[0]);
    };

    const handleKategoriVideoChange = (e) => {
        setIdKategoriVideo(e.target.value);
    };

    return (
        <main className="main-content">
            <div>
            {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}
                <h2 className="page-header">Video</h2>
                <Button
                    type="primary"
                    className="btn btn-primary text-white mb-3"
                    style={{ backgroundColor: '#007bff', height: '45px', fontFamily: 'poppins', width: '150px', borderRadius: '24px', marginTop: '10px' }}
                    onClick={handleShowCreate}
                >
                    Tambah Video
                </Button>

                <Table
                    columns={columns} 
                    dataSource={dataVideo}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                    className="table"
                />

                {/* Modal CREATE */}
                {showCreateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseCreate}>&times;</span>
                            <h2>Tambah Video</h2>
                            <form onSubmit={handleCreate}>
                                <div className="form-group">
                                    <label>Judul</label>
                                    <input
                                        type="text"
                                        onChange={handleJudulChange}
                                        value={judul}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <textarea
                                        rows={3}
                                        onChange={handleDeskripsiChange}
                                        value={deskripsi}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sumber</label>
                                    <input
                                        type="text"
                                        onChange={handleSumberChange}
                                        value={sumber}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Video Terapis</label>
                                    <input
                                        type="file"
                                        onChange={handleVideoTerapisChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Kategori Video</label>
                                    <select
                                        onChange={handleKategoriVideoChange}
                                        value={idKategoriVideo}
                                        className="form-control"
                                    >
                                        <option value="">Pilih Kategori</option>
                                        {kategoriVideo.map((kategori) => (
                                            <option key={kategori.id} value={kategori.id}>{kategori.kategori}</option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Tambah</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseCreate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal UPDATE */}
                {showUpdateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseUpdate}>&times;</span>
                            <h2>Update Video</h2>
                            <form onSubmit={handleUpdate}>
                                <div className="form-group">
                                    <label>Judul</label>
                                    <input
                                        type="text"
                                        onChange={handleJudulChange}
                                        value={judul}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <textarea
                                        rows={3}
                                        onChange={handleDeskripsiChange}
                                        value={deskripsi}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sumber</label>
                                    <input
                                        type="text"
                                        onChange={handleSumberChange}
                                        value={sumber}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Video Terapis</label>
                                    <input
                                        type="file"
                                        onChange={handleVideoTerapisChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Kategori Video</label>
                                    <select
                                        onChange={handleKategoriVideoChange}
                                        value={idKategoriVideo}
                                        className="form-control"
                                    >
                                        <option value="">Pilih Kategori</option>
                                        {kategoriVideo.map((kategori) => (
                                            <option key={kategori.id} value={kategori.id}>{kategori.kategori}</option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-update">Update</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseUpdate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Detail Data Video</h5>
                                    <div className="row">
                                        <p className="col-4 card-text">Judul</p>
                                        <p className="col-6 card-text">: {judul}</p>
                                    </div>
                                    <div className="row">
                                        <p className="col-4 card-text">Deskripsi</p>
                                        <p className="col-6 card-text">: {deskripsi}</p>
                                    </div>
                                    <div className="row">
                                        <p className="col-4 card-text">Sumber</p>
                                        <p className="col-6 card-text">: {sumber}</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default Video;
