import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import './Report.css'; // Pastikan Anda menambahkan file CSS untuk gaya kustom

// Helper function to fetch ibu name by ID
const fetchIbuName = async (id) => {
    try {
        const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${id}`);
        return response.data.nama;
    } catch (error) {
        console.error("Error fetching ibu data:", error);
        return 'Tidak diketahui';
    }
};

const Report = () => {
    const [reports, setReports] = useState([]);
    const [ibuNames, setIbuNames] = useState({});
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const fetchReports = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/report");
            setReports(response.data);

            // Fetch ibu names for all reports
            const names = {};
            for (let report of response.data) {
                if (report.create_by_ibu && !names[report.create_by_ibu]) {
                    names[report.create_by_ibu] = await fetchIbuName(report.create_by_ibu);
                }
                if (report.reported_ibu && !names[report.reported_ibu]) {
                    names[report.reported_ibu] = await fetchIbuName(report.reported_ibu);
                }
            }
            setIbuNames(names);
        } catch (error) {
            console.error("Error fetching reports:", error);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/report/delete/${id}`);
            setSuccessAlert(true);
            setSuccessMessage("Konten berhasil dihapus.");
            fetchReports(); // Perbarui data setelah penghapusan
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const getTypeReported = (record) => {
        if (record.id_thread) return 'Forum';
        if (record.id_reply) return 'Komentar';
        if (record.id_balasan_reply) return 'Komentar';
        return 'Unknown';
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Pembuat Laporan',
            dataIndex: 'create_by_ibu',
            key: 'create_by_ibu',
            render: (text, record) => ibuNames[record.create_by_ibu] || 'Loading...',
        },
        {
            title: 'Terlapor',
            dataIndex: 'reported_ibu',
            key: 'reported_ibu',
            render: (text, record) => record.reported_admin ? 'Admin' : (ibuNames[record.reported_ibu] || 'Loading...'),
        },
        {
            title: 'Konten',
            key: 'type_reported',
            render: (text, record) => getTypeReported(record),
        },
        {
            title: 'Isi',
            dataIndex: 'isi',
            key: 'isi',
        },
        {
            title: 'Alasan',
            dataIndex: 'alasan',
            key: 'alasan',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button 
                    icon={<DeleteOutlined />} 
                    onClick={() => handleDelete(record.id)}
                    className="delete-report-btn"
                >
                    Hapus
                </Button>
            ),
        },
    ];

    return (
        <main className="main-content">
            <div>
                {successAlert && <Alert message={successMessage} type="success" />}
                {failureAlert && <Alert message={failureMessage} type="error" />}
                <h2 className="page-header">Laporan</h2>
                <div className="card">
                    <div className="card__body">
                        <Table dataSource={reports} columns={columns} rowKey="id" />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Report;
