import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Isi_Audio.css';

export const Isi_Audio = () => {
  const [audios, setAudios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Array video YouTube dengan judul
  const youtubeVideos = [
    { id: 1, link: 'https://www.youtube.com/embed/RnIyb1yVs7Q', judul: 'Panduan Relaksasi Kehamilan - Hypnobirthing' },
    { id: 2, link: 'https://www.youtube.com/embed/RAvD_ylkFcA', judul: 'Afirmasi Kehamilan dan Persalinan (Untuk Muslim) - Tanpa Musik' },
    { id: 3, link: 'https://www.youtube.com/embed/4ffr26sUTLI', judul: 'Dengarkan Ini untuk Mengatasi Gangguan Kecemasan Berlebih (Anxiety) | Audio Therapy' },
    { id: 4, link: 'https://www.youtube.com/embed/ZpDRIOYWtCs', judul: 'Afirmasi Positif untuk Hamil Sehat bagi Bumil | Relaksasi Kesehatan Ibu Hamil' },
    { id: 5, link: 'https://www.youtube.com/embed/mxokVTWd7zQ', judul: 'MEMBANGUN KELUARGA YANG BAHAGIA ???? CARI TAU RESEPNYA DISINI | Motivasi Merry | Merry Riana' },
    { id: 6, link: 'https://www.youtube.com/embed/nbfCqwjahGs', judul: 'Panduan Relaksasi Persalinan - Hypnobirthing Saat Pembukaan Kontraksi Lahiran' },
    { id: 7, link: 'https://www.youtube.com/embed/8PzegYWUJjY', judul: 'Panduan Relaksasi Untuk Ibu (Audio Only) Bersama @intaniaal - Special Mother' },
    { id: 8, link: 'https://www.youtube.com/embed/wSqwda1peR8', judul: 'Cara Mengatasi Stres pada Ibu Rumah Tangga Ketika Mengasuh Anak' },
    { id: 9, link: 'https://www.youtube.com/embed/oqJh0-71q8U', judul: 'Sering Mengalami Stress? Atasi Dengan Mendengarkan Afirmasi Ini! | Meditasi Menenangkan Pikiran' },
    { id: 10, link: 'https://www.youtube.com/embed/c47ONTAvo3E', judul: 'RELAKSASI HYPNOBIRTHING KEHAMILAN - MEMINIMALISASI TRAUMA DALAM KEHAMILAN' },
    { id: 11, link: 'https://www.youtube.com/embed/bL48Wh7n1pk', judul: 'Pikiran Tidak Tenang? Lakukan Meditasi Ini Untuk Menenangkan Pikiranmu! | Afirmasi Positif' }
  ];

  useEffect(() => {
    // Sementara gunakan link YouTube
    setAudios(youtubeVideos.map(video => ({
      id: video.id,
      audio_terapis: video.link,
      judul: video.judul,
      sumber: 'YouTube'
    })));
  }, []);

  // Fungsi untuk meng-handle perubahan pencarian
  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  // Lakukan filter audio berdasarkan pencarian
  const filteredAudios = audios.filter(audio =>
    audio.judul.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className='audio-terapis-container-all-isi'>
        <div className="intro-audio-beranda">
          <h1>Temukan Ketenangan yang Damai</h1>
          <p>Tempat terbaik untuk para ibu mendapatkan audio relaksasi, afirmasi positif dan lainnya</p>
        </div>
        <div className="search-audio">
          <input
            type="text"
            placeholder=" 🔍 Cari audio..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="audio-terapis-container-list">
        {filteredAudios.map(audio => (
          <div key={audio.id} className="audio-terapis-item">
            <iframe
              title={audio.judul}
              width="100%"
              height="200"
              src={audio.audio_terapis}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h2>{audio.judul}</h2>
            <p>Sumber: {audio.sumber}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Isi_Audio;
