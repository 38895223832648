import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './Thread.css';

const Thread = () => {
    const [threads, setThreads] = useState([]);
    const [ibuNames, setIbuNames] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryMap, setCategoryMap] = useState({});
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [newThread, setNewThread] = useState({
        judul: '',
        isi: '',
        id_kategori_thread: '',
        create_by_ibu: localStorage.getItem('id'),
        gambar_thread: null
    });

    const navigate = useNavigate();

    const fetchThreads = async () => {
        try {
            const response = await axios.get('https://sipbibu.tifpsdku.com/backend/public/thread');
            setThreads(response.data);
        } catch (error) {
            console.error('Error fetching threads:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://sipbibu.tifpsdku.com/backend/public/kategori-thread');
            const categories = response.data;
            setCategories(categories);
            const map = categories.reduce((acc, category) => {
                acc[category.id] = category.kategori;
                return acc;
            }, {});
            setCategoryMap(map);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchIbuName = async (ibuId) => {
        try {
            const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${ibuId}`);
            const namaIbu = response.data.nama;
            setIbuNames((prevNames) => ({
                ...prevNames,
                [ibuId]: namaIbu,
            }));
        } catch (error) {
            console.error('Error fetching ibu name:', error);
            setIbuNames((prevNames) => ({
                ...prevNames,
                [ibuId]: 'Error',
            }));
        }
    };

    useEffect(() => {
        fetchThreads();
        fetchCategories();
    }, []);

    useEffect(() => {
        threads.forEach((thread) => {
            if (thread.create_by_ibu && !ibuNames[thread.create_by_ibu]) {
                fetchIbuName(thread.create_by_ibu);
            }
        });
    }, [threads, ibuNames]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'judul' && value.length > 50) {
            return;
        }
        setNewThread({
            ...newThread,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setNewThread({
            ...newThread,
            gambar_thread: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in newThread) {
            formData.append(key, newThread[key]);
        }

        try {
            await axios.post('https://sipbibu.tifpsdku.com/backend/public/thread', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowCreateForm(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Forum berhasil dibuat'
            });
            fetchThreads();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Terjadi kesalahan saat membuat Forum'
            });
            console.error('Error creating thread:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/thread/${id}`);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Forum berhasil dihapus'
            });
            fetchThreads();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Terjadi kesalahan saat menghapus Forum'
            });
            console.error('Error deleting thread:', error);
        }
    };

    const handleReport = async (id_thread) => {
        const create_by_ibu = localStorage.getItem('id');

        const { value: alasan } = await Swal.fire({
            title: 'Laporkan Forum',
            input: 'textarea',
            inputLabel: 'Alasan',
            inputPlaceholder: 'Tuliskan alasan Anda melaporkan forum ini...',
            inputAttributes: {
                'aria-label': 'Tuliskan alasan Anda melaporkan forum ini'
            },
            showCancelButton: true,
            customClass: {
                popup: 'custom-swal-popup',
                title: 'custom-swal-title',
                input: 'custom-swal-input'
            }
        });

        if (alasan) {
            try {
                const threadResponse = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/thread/${id_thread}`);
                const threadData = threadResponse.data;

                const reportData = {
                    id_thread,
                    alasan,
                    create_by_ibu,
                    reported_ibu: threadData.create_by_ibu ? threadData.create_by_ibu : null,
                    reported_admin: threadData.create_by_admin ? threadData.create_by_admin : null
                };

                await axios.post('https://sipbibu.tifpsdku.com/backend/public/report/create', reportData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                Swal.fire({
                    icon: 'success',
                    title: 'Laporan Berhasil',
                    text: 'Forum telah dilaporkan'
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Gagal melaporkan Forum'
                });
                console.error('Error reporting thread:', error);
            }
        }
    };

    const navigateToThread = (id) => {
        navigate(`/thread/${id}`);
    };

    const filteredThreads = threads.filter((thread) =>
        thread.judul.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="thread-container">
            <div className="thread-intro-card">
          <h1>Selamat Datang di Forum Diskusi Ibu!</h1>
          <p>"Kami menyediakan Forum Diskusi Ibu khusus bagi para ibu hamil yang ingin berbagi cerita, 
          mendapatkan saran, dan saling mendukung dalam komunitas yang hangat dan penuh perhatian"</p>
        </div>
            <div className="search-container">
                <h6>Temukan Forum Yang Kamu Mau!</h6>
                <input
                    type="text"
                    placeholder="🔍 Cari Forum..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className='info-above-thread'>
                <button className="create-thread-tombol" onClick={() => setShowCreateForm(true)}>
                <i class="fa-solid fa-comments"></i> Yuk! Buat Forum
                </button>
            </div>

            {showCreateForm && (      
            <div className="create-thread-form-overlay">
                <div className="create-form-thread">
                    <h2>Buat Forum Kamu!</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <h1>Judul  :</h1>
                            <input id="judul" type="text" name="judul" value={newThread.judul} onChange={handleInputChange} maxLength="50" required />
                        </label>
                        <label>
                            <h1>Isi  :</h1>
                            <textarea id="isi" name="isi" value={newThread.isi} onChange={handleInputChange} required></textarea>
                        </label>
                        <label>
                            <h1>Kategori Forum  :</h1>
                            <select id="id_kategori_thread" name="id_kategori_thread" value={newThread.id_kategori_thread} onChange={handleInputChange} required>
                                <option value="">Pilih Kategori</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.kategori}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            <h1>Gambar Untuk Forum  :</h1>
                            <input id="gambar_thread" type="file" name="gambar_thread" onChange={handleFileChange} />
                        </label>
                        <button type="submit">Buat Forum</button>
                        <button type="button" onClick={() => setShowCreateForm(false)}>Kembali</button>
                    </form>
                </div>
            </div>
            )}
            <div className="thread-cards">
                {filteredThreads.map((thread) => (
                    <div className="thread-card" key={thread.id} onClick={() => navigateToThread(thread.id)}>
                        {thread.gambar_thread && (
                            <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${thread.gambar_thread}`} alt="Gambar Thread" className="thread-image" />
                        )}
                        <div className="thread-content">
                            <h3>{categoryMap[thread.id_kategori_thread]}</h3>
                            <p>{thread.judul}</p>
                            <h2>Creator : {thread.create_by_admin ? 'Admin' : (ibuNames[thread.create_by_ibu] || 'Loading...')}</h2>
                        </div>
                        <div className="thread-options">
                            <span className="tooltip-container">
                                <span onClick={(e) => { e.stopPropagation(); handleReport(thread.id); }} className="report-thread-btn">
                                    <i className="fa-regular fa-flag"></i>
                                </span>
                                <span className="tooltip-text">Laporkan</span>
                            </span>
                            {localStorage.getItem('id') === thread.create_by_ibu && (
                                <span className="tooltip-container">
                                    <span onClick={(e) => { e.stopPropagation(); handleDelete(thread.id); }} className="delete-thread-btn">
                                        <i className="fa-regular fa-trash-can"></i>
                                    </span>
                                    <span className="tooltip-text">Hapus</span>
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Thread;
