import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Audio = () => {
    const [id, setId] = useState("");
    const [judul, setJudul] = useState("");
    const [deskripsi, setDeskripsi] = useState("");
    const [sumber, setSumber] = useState("");
    const [audio_terapis, setAudioTerapis] = useState(null);
    const [idKategoriAudio, setIdKategoriAudio] = useState("");
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataAudio, setDataAudio] = useState([]);
    const [kategoriAudio, setKategoriAudio] = useState([]); // Inisialisasi dengan array kosong
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/audio");
            setDataAudio(response.data.data_audio);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchKategoriAudio = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/kategori-audio");
            setKategoriAudio(response.data); // Memperbarui state kategoriAudio dengan data yang diterima dari server
        } catch (error) {
            console.error("Error fetching kategori audio:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchKategoriAudio(); // Panggil fungsi fetchKategoriAudio di sini
    }, []);

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('judul', judul);
            formData.append('deskripsi', deskripsi);
            formData.append('sumber', sumber);
            formData.append('id_kategori_audio', idKategoriAudio);
            if (audio_terapis !== null) {
                formData.append('audio_terapis', audio_terapis);
            }

            await axios.post(
                `https://sipbibu.tifpsdku.com/backend/public/audio/ubah/${id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil diubah.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseUpdate();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat mengubah data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (judul === "" || deskripsi === "" || sumber === "" || !audio_terapis || idKategoriAudio === "") {
            console.error("Data Gagal ditambahkan, field tidak boleh ada yang kosong");
        } else {
            try {
                const formData = new FormData();
                formData.append('judul', judul);
                formData.append('deskripsi', deskripsi);
                formData.append('sumber', sumber);
                formData.append('audio_terapis', audio_terapis);
                formData.append('id_kategori_audio', idKategoriAudio);

                await axios.post(
                    'https://sipbibu.tifpsdku.com/backend/public/audio',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                navigate("/admin/audio");

                setSuccessAlert(true);
                setSuccessMessage("Data berhasil ditambahkan.");
                setTimeout(() => {
                    setSuccessAlert(false);
                    setSuccessMessage("");
                }, 3000);
                handleCloseCreate();
                fetchData();
            } catch (error) {
                setFailureAlert(true);
                setFailureMessage("Terjadi kesalahan saat menambahkan data.");
                setTimeout(() => {
                    setFailureAlert(false);
                    setFailureMessage("");
                }, 3000);
            }
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/audio/${id}`);
            fetchData();
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };
    

    const handleShowUpdate = async (data) => {
        setId(data.id);
        setJudul(data.judul);
        setDeskripsi(data.deskripsi);
        setSumber(data.sumber);
        setAudioTerapis(data.audio_terapis);
        setIdKategoriAudio(data.id_kategori_audio);
        setShowUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setId("");
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setAudioTerapis(null);
        setIdKategoriAudio("");
        setShowUpdateModal(false);
    };

    const handleShowCreate = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreate = () => {
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setAudioTerapis(null);
        setIdKategoriAudio("");
        setShowCreateModal(false);
    };

    const handleShowDelete = (data) => {
        setId(data.id);
        setJudul(data.judul);
        setDeskripsi(data.deskripsi);
        setSumber(data.sumber);
        setAudioTerapis(data.audio_terapis);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setId("");
        setJudul("");
        setDeskripsi("");
        setSumber("");
        setAudioTerapis(null);
        setShowDeleteModal(false);
    };

    const handleJudulChange = (e) => {
        setJudul(e.target.value);
    };

    const handleDeskripsiChange = (e) => {
        setDeskripsi(e.target.value);
    };

    const handleSumberChange = (e) => {
        setSumber(e.target.value);
    };

    const handleAudioTerapisChange = (e) => {
        setAudioTerapis(e.target.files[0]);
    };

    const handleKategoriAudioChange = (e) => {
        setIdKategoriAudio(e.target.value);
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Judul',
            dataIndex: 'judul',
            key: 'judul',
        },
        {
            title: 'Deskripsi',
            dataIndex: 'deskripsi',
            key: 'deskripsi',
        },
        {
            title: 'Sumber',
            dataIndex: 'sumber',
            key: 'sumber',
        },
        {
            title: 'Audio Terapis',
            dataIndex: 'audio_terapis',
            key: 'audio_terapis',
            render: (audio_terapis) => (
                <video width="320" height="240" controls>
                    <source src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${audio_terapis}`} type="video/mp4" />
                    Your browser does not support the audio element.
                </video>
            ),
        },
        {
            title: 'Kategori Audio',
            dataIndex: 'id_kategori_audio',
            key: 'id_kategori_audio',
            render: (id_kategori_audio) => {
                const kategori = kategoriAudio.find(k => k.id === id_kategori_audio);
                return kategori ? kategori.kategori : 'Tidak diketahui';
            }
        },
        {
            title: "Aksi",
            key: "action",
            render: (text, record) => (
                <span>
                    <Button
                        className="btn btn-warning text-black me-2"
                        style={{ backgroundColor: 'yellow', borderColor: 'yellow', color: 'black', height: '45px', marginBottom: '10px' }}
                        onClick={() => handleShowUpdate(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="btn btn-danger text-white"
                        style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                        onClick={() => handleShowDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <main className="main-content">
            <div>
            {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}

                <h2 className="page-header">Audio</h2>
                <Button
                    type="primary"
                    className="mb-3"
                    style={{ backgroundColor: '#007bff', height: '45px', fontFamily: 'poppins', width: '150px', borderRadius: '24px', marginTop: '10px', fontSize: '16px' }}
                    onClick={handleShowCreate}
                >
                    Tambah Audio
                </Button>

                

                {/* Modal CREATE */}
                {showCreateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseCreate}>&times;</span>
                            <h2>Tambah Audio</h2>
                            <form onSubmit={handleCreate}>
                                <div className="form-group">
                                    <label>Judul</label>
                                    <input
                                        type="text"
                                        onChange={handleJudulChange}
                                        value={judul}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <textarea
                                        rows={3}
                                        onChange={handleDeskripsiChange}
                                        value={deskripsi}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sumber</label>

                                    <input
                                        type="text"
                                        onChange={handleSumberChange}
                                        value={sumber}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Audio Terapis</label>
                                    <input
                                        type="file"
                                        onChange={handleAudioTerapisChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Kategori Audio</label>
                                    <select
                                        onChange={handleKategoriAudioChange}
                                        value={idKategoriAudio}
                                        className="form-control"
                                    >
                                        <option value="">Pilih Kategori</option>
                                        {kategoriAudio.map((kategori) => (
                                            <option key={kategori.id} value={kategori.id}>{kategori.kategori}</option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Tambah</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseCreate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal UPDATE */}
                {showUpdateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseUpdate}>&times;</span>
                            <h2>Edit Audio</h2>
                            <form onSubmit={handleUpdate}>
                                <div className="form-group">
                                    <label>Judul</label>
                                    <input
                                        type="text"
                                        onChange={handleJudulChange}
                                        value={judul}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <textarea
                                        rows={3}
                                        onChange={handleDeskripsiChange}
                                        value={deskripsi}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sumber</label>
                                    <input
                                        type="text"
                                        onChange={handleSumberChange}
                                        value={sumber}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Audio Terapis</label>
                                    <input
                                        type="file"
                                        onChange={handleAudioTerapisChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Kategori Audio</label>
                                    <select
                                        onChange={handleKategoriAudioChange}
                                        value={idKategoriAudio}
                                        className="form-control"
                                    >
                                        <option value="">Pilih Kategori</option>
                                        {kategoriAudio.map((kategori) => (
                                            <option key={kategori.id} value={kategori.id}>{kategori.kategori}</option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-update">Update</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseUpdate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Detail Data Audio</h5>
                                    <div className="row">
                                        <p className="col-4 card-text">Judul</p>
                                        <p className="col-6 card-text">: {judul}</p>
                                    </div>
                                    <div className="row">
                                        <p className="col-4 card-text">Deskripsi</p>
                                        <p className="col-6 card-text">: {deskripsi}</p>
                                    </div>
                                    <div className="row">
                                        <p className="col-4 card-text">Sumber</p>
                                        <p className="col-6 card-text">: {sumber}</p>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                            <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                        </div>
                    </div>
                )}
            </div>
            <div className="card">
                <div className="card__body">
                    <Table dataSource={dataAudio} columns={columns} rowKey="id" pagination={{ pageSize: 5 }}/>
                </div>
            </div>
        </main>
    );
};

export default Audio;
