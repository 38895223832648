import React from 'react';
import './styles.css';

const Dashboard = () => {
  return (
    <main className="main-content">
      <div className="card">
        <h2>Selamat Datang di Halaman Admin SIPBIBU</h2>
        <p>Anda memiliki peran penting dalam menjaga integritas dan kualitas website ini. Bertindaklah dengan tanggung jawab dan etika.</p>
      </div>
    </main>
  );
};

export default Dashboard;
