import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
const Kuisioner2 = () => {
    const [total_skor_internal, setTotalSkorInternal] = useState("");
    const [total_skor_eksternal, setTotalSkorEksternal] = useState("");
    const [hasil_kesimpulan, setHasilKesimpulan] = useState("");
    const [dataKuisioner, setDataKuisioner] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedKuisioner, setSelectedKuisioner] = useState(null);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/semuaHasilKuisioner_2"); // Ganti endpoint dengan endpoint yang sesuai
            setDataKuisioner(response.data); // Respons dari endpoint sekarang berisi seluruh data, tidak hanya satu data
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/delete/simpanHasil_2/${selectedKuisioner.id}`);
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            fetchData(); // Perbarui data setelah penghapusan
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete(); // Tutup modal setelah penghapusan berhasil
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleShowDelete = (record) => {
        setTotalSkorInternal(record.total_skor_internal);
        setTotalSkorEksternal(record.total_skor_eksternal);
        setHasilKesimpulan(record.hasil_kesimpulan);
        setSelectedKuisioner(record);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setTotalSkorInternal("");
        setTotalSkorEksternal("");
        setHasilKesimpulan("");
        setShowDeleteModal(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Total Skor Internal',
            dataIndex: 'total_skor_internal',
            key: 'total_skor_internal',
        },
        {
            title: 'Total Skor Eksternal',
            dataIndex: 'total_skor_eksternal',
            key: 'total_skor_eksternal',
        },
        {
            title: 'Hasil Kesimpulan',
            dataIndex: 'hasil_kesimpulan',
            key: 'hasil_kesimpulan',
        },
        {
            title: 'Aksi',
            key: 'aksi',
            render: (text, record) => (
                <Button
                    className="btn btn-danger text-white"
                    style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                    onClick={() => handleShowDelete(record)}
                >
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    return (
        <main className="main-content">
            <div>
            {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}
                
                <h2>Tes Baby Blues Pasca Melahirkan </h2>
                <Table
                    columns={columns}
                    dataSource={dataKuisioner}
                    className="table"
                />
                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <p>
                                <strong>Total Skor Internal:</strong> {total_skor_internal}
                            </p>
                            <p>
                                <strong>Total Skor Eksternal:</strong> {total_skor_eksternal}
                            </p>
                            <p>
                                <strong>Hasil Kesimpulan:</strong> {hasil_kesimpulan}
                            </p>
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </main>
    );
};

export default Kuisioner2;
