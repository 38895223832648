import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Artikel = () => {
    const [id, setId] = useState("");
    const [kategori, setKategori] = useState("");
    const [judul, setJudul] = useState("");
    const [isi, setIsi] = useState("");
    const [penulis, setPenulis] = useState("");
    const [pendahuluan, setPendahuluan] = useState("");
    const [penutup, setPenutup] = useState("");
    const [cover, setCover] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataArtikel, setDataArtikel] = useState([]);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/artikel");
            setDataArtikel(response.data.data_artikel);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('kategori', kategori);
            formData.append('judul', judul);
            formData.append('penulis', penulis);
            formData.append('cover', cover);
            formData.append('pendahuluan', pendahuluan);
            formData.append('isi', isi);
            formData.append('penutup', penutup);

            const putData = await axios.post(
                `https://sipbibu.tifpsdku.com/backend/public/update/artikel/${id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setSuccessAlert(true);
            setSuccessMessage("Data berhasil diubah.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseUpdate();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat mengubah data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        if (kategori === "" || judul === "" || penulis === "" || !cover || pendahuluan === "" || isi === "" || penutup === "") {
            alert("Data gagal ditambahkan, field tidak boleh ada yang kosong");
        } else {
            try {
                const formData = new FormData();
                formData.append('kategori', kategori);
                formData.append('judul', judul);
                formData.append('penulis', penulis);
                formData.append('cover', cover);
                formData.append('pendahuluan', pendahuluan);
                formData.append('isi', isi);
                formData.append('penutup', penutup);

                await axios.post(
                    'https://sipbibu.tifpsdku.com/backend/public/artikel',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                setSuccessAlert(true);
                setSuccessMessage("Data berhasil ditambahkan.");
                setTimeout(() => {
                    setSuccessAlert(false);
                    setSuccessMessage("");
                }, 3000);
                handleCloseCreate();
                fetchData();
            } catch (error) {
                setFailureAlert(true);
                setFailureMessage("Terjadi kesalahan saat menambahkan data.");
                setTimeout(() => {
                    setFailureAlert(false);
                    setFailureMessage("");
                }, 3000);
                console.error("Error:", error);
            }
        }
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        try {
            const deleteData = await axios.delete(
                `https://sipbibu.tifpsdku.com/backend/public/delete/artikel/${id}`
            );
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleShowUpdate = (data) => {
        setId(data.id);
        setKategori(data.kategori);
        setJudul(data.judul);
        setPenulis(data.penulis);
        setPendahuluan(data.pendahuluan);
        setIsi(data.isi);
        setPenutup(data.penutup);
        setShowUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setId("");
        setKategori("");
        setJudul("");
        setPenulis("");
        setPendahuluan("");
        setIsi("");
        setPenutup("");
        setShowUpdateModal(false);
    };

    const handleShowCreate = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreate = () => {
        setKategori("");
        setJudul("");
        setPenulis("");
        setPendahuluan("");
        setIsi("");
        setPenutup("");
        setCover(null);
        setShowCreateModal(false);
    };

    const handleShowDelete = (data) => {
        setId(data.id);
        setKategori(data.kategori);
        setJudul(data.judul);
        setPenulis(data.penulis);
        setPendahuluan(data.pendahuluan);
        setIsi(data.isi);
        setPenutup(data.penutup);

        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setId("");
        setKategori("");
        setJudul("");
        setPenulis("");
        setPendahuluan("");
        setIsi("");
        setPenutup("");
        setShowDeleteModal(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Mengurutkan dataArtikel dari yang terkecil ke terbesar berdasarkan ID
    const sortedDataArtikel = [...dataArtikel].sort((a, b) => a.id - b.id);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'ascend', // Menambah properti defaultSortOrder untuk mengurutkan dari terkecil ke terbesar
        },
        {
            title: 'Kategori',
            dataIndex: 'kategori',
            key: 'kategori',
        },
        {
            title: 'Judul',
            dataIndex: 'judul',
            key: 'judul',
        },
        {
            title: 'Penulis',
            dataIndex: 'penulis',
            key: 'penulis',
        },
        {
            title: 'Pendahuluan',
            dataIndex: 'pendahuluan',
            key: 'pendahuluan',
        },
        {
            title: 'Isi',
            dataIndex: 'isi',
            key: 'isi',
        },
        {
            title: 'Penutup',
            dataIndex: 'penutup',
            key: 'penutup',
        },
        {
            title: 'Cover',
            dataIndex: 'cover',
            key: 'cover',
            render: (cover) => (
                <img
                    src={'https://sipbibu.tifpsdku.com/backend/public/uploads/' + cover}
                    alt="Cover"
                    style={{ width: "100px", height: "auto" }}
                />
            )
        },
        {
            title: "Aksi",
            key: "action",
            render: (text, record) => (
                <span>
                    <Button
                        className="btn btn-warning text-black me-2"
                        style={{ backgroundColor: 'yellow', borderColor: 'yellow', color: 'black', height: '45px', marginBottom: '10px' }}
                        onClick={() => handleShowUpdate(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="btn btn-danger text-white"
                        style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                        onClick={() => handleShowDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ]; const handleCoverChange = (e) => {
        setCover(e.target.files[0]);
    };

    return (
        <main className="main-content">
            <div>
                {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}

                <h2 className="page-header">Rubrik's Mom</h2>
                <Button
                    type="primary"
                    className="btn btn-primary text-white mb-3"
                    style={{ backgroundColor: '#007bff', height: '45px', fontFamily: 'poppins', width: '150px', borderRadius: '24px', marginTop: '10px' }}
                    onClick={handleShowCreate}
                >
                    Tambah Data
                </Button>

                {/* Modal CREATE */}
                {showCreateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseCreate}>&times;</span>
                            <h2>Form Tambah Artikel</h2>
                            <form onSubmit={handleCreate}>
                                <div className="form-group">
                                    <label htmlFor="formKategoriCreate">Kategori</label>
                                    <input
                                        type="text"
                                        id="formKategoriCreate"
                                        autoFocus
                                        onChange={(e) => setKategori(e.target.value)}
                                        value={kategori}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formJudulCreate">Judul</label>
                                    <input
                                        type="text"
                                        id="formJudulCreate"
                                        onChange={(e) => setJudul(e.target.value)}
                                        value={judul}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPenulisCreate">Penulis</label>
                                    <input
                                        type="text"
                                        id="formPenulisCreate"
                                        onChange={(e) => setPenulis(e.target.value)}
                                        value={penulis}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formCoverCreate">Cover</label>
                                    <input
                                        type="file"
                                        id="formCoverCreate"
                                        accept="image/*"
                                        onChange={handleCoverChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPendahuluanCreate">Pendahuluan</label>
                                    <textarea
                                        id="formPendahuluanCreate"
                                        onChange={(e) => setPendahuluan(e.target.value)}
                                        value={pendahuluan}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formIsiCreate">Isi</label>
                                    <textarea
                                        id="formIsiCreate"
                                        onChange={(e) => setIsi(e.target.value)}
                                        value={isi}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPenutupCreate">Penutup</label>
                                    <textarea
                                        id="formPenutupCreate"
                                        onChange={(e) => setPenutup(e.target.value)}
                                        value={penutup}
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Tambah</button>
                                    <button type="button" className="btn btn-close" onClick={handleCloseCreate}>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal Edit */}
                {showUpdateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseUpdate}>&times;</span>
                            <h2>Form Update Artikel</h2>
                            <form onSubmit={handleUpdate}>
                                <div className="form-group">
                                    <label htmlFor="formKategori">Kategori</label>
                                    <input
                                        type="text"
                                        id="formKategori"
                                        autoFocus
                                        onChange={(e) => setKategori(e.target.value)}
                                        value={kategori}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formJudul">Judul</label>
                                    <input
                                        type="text"
                                        id="formJudul"
                                        onChange={(e) => setJudul(e.target.value)}
                                        value={judul}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPenulis">Penulis</label>
                                    <input
                                        type="text"
                                        id="formPenulis"
                                        onChange={(e) => setPenulis(e.target.value)}
                                        value={penulis}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formCover">Cover</label>
                                    <input
                                        type="file"
                                        id="formCover"
                                        accept="image/*"
                                        onChange={handleCoverChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPendahuluan">Pendahuluan</label>
                                    <textarea
                                        id="formPendahuluan"
                                        onChange={(e) => setPendahuluan(e.target.value)}
                                        value={pendahuluan}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formIsi">Isi</label>
                                    <textarea
                                        id="formIsi"
                                        onChange={(e) => setIsi(e.target.value)}
                                        value={isi}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPenutup">Penutup</label>
                                    <textarea
                                        id="formPenutup"
                                        onChange={(e) => setPenutup(e.target.value)}
                                        value={penutup}
                                    />
                                </div>
                                <button type="submit" className="btn btn-update">Update</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseUpdate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}


                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <p>
                                <strong>Kategori:</strong> {kategori}
                            </p>
                            <p>
                                <strong>Judul:</strong> {judul}
                            </p>
                            <p>
                                <strong>Penulis:</strong> {penulis}
                            </p>
                            <p>
                                <strong>Cover:</strong> {cover}
                            </p>
                            <p>
                                <strong>Pendahuluan:</strong> {pendahuluan}
                            </p>
                            <p>
                                <strong>Isi:</strong> {isi}
                            </p>
                            <p>
                                <strong>Penutup:</strong> {penutup}
                            </p>
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}


                <Table
                    columns={columns}
                    dataSource={sortedDataArtikel}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </div>
        </main>
    );
};

export default Artikel;
