import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Ibu = () => {
    const [id, setId] = useState("");
    const [nama, setNama] = useState("");
    const [no_telp, setNoTelp] = useState("");
    const [alamat, setAlamat] = useState("");
    const [usia, setUsia] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [id_kategori_audio, setIdKategoriAudio] = useState("");
    const [id_kategori_video, setIdKategoriVideo] = useState("");
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertFailure, setAlertFailure] = useState(false);

    const navigate = useNavigate();

    const [dataIbu, setDataIbu] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/data-ibu/");
            setDataIbu(response.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("nama", nama);
            formData.append("no_telp", no_telp);
            formData.append("alamat", alamat);
            formData.append("usia", usia);
            formData.append("email", email);
            formData.append("password", password);
            formData.append("id_kategori_audio", id_kategori_audio);
            formData.append("id_kategori_video", id_kategori_video);
            if (avatar) {
                formData.append("avatar", avatar);
            }

            const putData = await axios.post(
                `https://sipbibu.tifpsdku.com/backend/public/update/data-ibu/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setAlertSuccess(true);
            fetchData();
            setTimeout(() => {
                setAlertSuccess(false);
            }, 3000);
            handleCloseUpdate();
        } catch (error) {
            setAlertFailure(true);
            setTimeout(() => {
                setAlertFailure(false);
            }, 3000);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        const ibuExist = dataIbu.find(ibu => ibu.email === email);
        if (ibuExist) {
            alert("Data dengan email tersebut sudah terdaftar.");
            return; // Keluar dari fungsi handleCreate jika data ibu sudah terdaftar
        }

        if (nama === "" || no_telp === "" || alamat === "" || usia === "" || email === "" || password === "" || !avatar) {
            alert("Data Gagal ditambahkan, field tidak boleh ada yang kosong");
        } else {
            try {
                const headers = {
                    "Content-Type": "multipart/form-data",
                };

                await axios.post(
                    'https://sipbibu.tifpsdku.com/backend/public/data-ibu/',
                    {
                        nama: nama,
                        no_telp: no_telp,
                        alamat: alamat,
                        usia: usia,
                        email: email,
                        password: password,
                        // id_kategori_audio: id_kategori_audio,
                        // id_kategori_video: id_kategori_video,
                        avatar: avatar,
                    },
                    {
                        headers: headers,
                    }
                );

                navigate("/admin/data-ibu");

                setAlertSuccess(true);
                fetchData();
                setTimeout(() => {
                    setAlertSuccess(false);
                }, 3000);
                handleCloseCreate();
            } catch (error) {
                setAlertFailure(true);
                setTimeout(() => {
                    setAlertFailure(false);
                }, 3000);
            }
        }
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        try {
            const deleteData = await axios.delete(
                `https://sipbibu.tifpsdku.com/backend/public/delete/data-ibu/${id}`
            );
            setAlertSuccess(true);
            fetchData();
            setTimeout(() => {
                setAlertSuccess(false);
            }, 3000);
            handleCloseDelete();

        } catch (error) {
            setAlertFailure(true);
            setTimeout(() => {
                setAlertFailure(false);
            }, 3000);
        }
    };

    const handleShowUpdate = (data) => {
        setId(data.id);
        setNama(data.nama);
        setNoTelp(data.no_telp);
        setAlamat(data.alamat);
        setUsia(data.usia);
        setEmail(data.email);
        setPassword(data.password);
        // setIdKategoriAudio(data.id_kategori_audio);
        // setIdKategoriVideo(data.id_kategori_video);
        setShowUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setId("");
        setNama("");
        setNoTelp("");
        setAlamat("");
        setUsia("");
        setEmail("");
        setPassword("");
        // setIdKategoriAudio("");
        // setIdKategoriVideo("");
        setShowUpdateModal(false);
    };

    const handleShowCreate = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreate = () => {
        setNama("");
        setNoTelp("");
        setAlamat("");
        setUsia("");
        setEmail("");
        setPassword("");
        // setIdKategoriAudio("");
        // setIdKategoriVideo("");
        setAvatar("");
        setShowCreateModal(false);
    };

    const handleShowDelete = (data) => {
        setId(data.id);
        setNama(data.nama);
        setNoTelp(data.no_telp);
        setAlamat(data.alamat);
        setUsia(data.usia);
        setEmail(data.email);
        setPassword(data.password);
        // setIdKategoriAudio(data.id_kategori_audio);
        // setIdKategoriVideo(data.id_kategori_video);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setId("");
        setNama("");
        setNoTelp("");
        setAlamat("");
        setUsia("");
        setEmail("");
        setPassword("");
        // setIdKategoriAudio("");
        // setIdKategoriVideo("");
        setShowDeleteModal(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nama',
            dataIndex: 'nama',
            key: 'nama',
        },
        {
            title: 'No. Telepon',
            dataIndex: 'no_telp',
            key: 'no_telp',
        },
        {
            title: 'Alamat',
            dataIndex: 'alamat',
            key: 'alamat',
        },
        {
            title: 'Usia',
            dataIndex: 'usia',
            key: 'usia',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
        },
        // {
        //     title: 'ID Kategori Audio dan Video',
        //     dataIndex: 'id_kategori_video',
        //     key: 'id_kategori_video',
        // },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: 'avatar',
            render: (avatar) => (
                <img
                    src={'https://sipbibu.tifpsdku.com/backend/public/uploads/' + avatar}
                    alt="Avatar"
                    style={{ width: "100px", height: "auto" }}
                />
            )
        },
        {
            title: "Aksi",
            key: "action",
            render: (text, record) => (
                <span>
                    <Button
                        className="btn btn-warning text-black me-2"
                        style={{ backgroundColor: 'yellow', borderColor: 'yellow', color: 'black', height: '45px', marginBottom: '10px' }}
                        onClick={() => handleShowUpdate(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="btn btn-danger text-white"
                        style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                        onClick={() => handleShowDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ];

    const handleAvatarChange = (e) => {
        setAvatar(e.target.files[0]);
    };

    return (
        <main className="main-content">
            <div>
                {alertSuccess && (
                    <Alert
                        message="Sukses"
                        description="Data berhasil diubah."
                        type="success"
                        onClose={() => setAlertSuccess(false)}
                        showIcon
                    />
                )}

                {alertFailure && (
                    <Alert
                        message="Gagal"
                        description="Gagal mengubah data."
                        type="error"
                        onClose={() => setAlertFailure(false)}
                        showIcon
                    />
                )}

                <h2 className="page-header">Data Ibu</h2>
                <Button
                    type="primary"
                    className="btn btn-primary text-white mb-3"
                    style={{ backgroundColor: '#007bff', height: '45px', fontFamily: 'poppins', width: '150px', borderRadius: '24px', marginTop: '10px' }}
                    onClick={handleShowCreate}
                >
                    Tambah Data
                </Button>

                {/* Modal CREATE */}
                {showCreateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseCreate}>&times;</span>
                            <h2>Form Tambah Data</h2>
                            <form onSubmit={handleCreate}>
                                <div className="form-group">
                                    <label htmlFor="formNamaCreate">Nama</label>
                                    <input
                                        type="text"
                                        id="formNamaCreate"
                                        autoFocus
                                        onChange={(e) => setNama(e.target.value)}
                                        value={nama}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formNoTelpCreate">No. Telpon</label>
                                    <input
                                        type="text"
                                        id="formNoTelpCreate"
                                        onChange={(e) => setNoTelp(e.target.value)}
                                        value={no_telp}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formAlamatCreate">Alamat</label>
                                    <input
                                        type="text"
                                        id="formAlamatCreate"
                                        onChange={(e) => setAlamat(e.target.value)}
                                        value={alamat}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formUsiaCreate">Usia</label>
                                    <input
                                        type="text"
                                        id="formUsiaCreate"
                                        onChange={(e) => setUsia(e.target.value)}
                                        value={usia}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formEmailCreate">Email</label>
                                    <input
                                        type="text"
                                        id="formEmailCreate"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPasswordCreate">Password</label>
                                    <input
                                        type="password"
                                        id="formPasswordCreate"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="formKategoriAudioCreate">ID Kategori Audio</label>
                                    <input
                                        type="text"
                                        id="formKategoriAudioCreate"
                                        onChange={(e) => setIdKategoriAudio(e.target.value)}
                                        value={id_kategori_audio}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formKategoriVideoCreate">ID Kategori Video</label>
                                    <input
                                        type="text"
                                        id="formKategoriVideoCreate"
                                        onChange={(e) => setIdKategoriVideo(e.target.value)}
                                        value={id_kategori_video}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="formAvatarCreate">Avatar</label>
                                    <input
                                        type="file"
                                        id="formAvatarCreate"
                                        accept="image/*"
                                        onChange={handleAvatarChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Tambah</button>
                                    <button type="button" className="btn btn-close" onClick={handleCloseCreate}>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}


                {/* Modal EDIT */}
                {showUpdateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseUpdate}>&times;</span>
                            <h2>Form Update Data</h2>
                            <form onSubmit={handleUpdate}>
                                <div className="form-group">
                                    <label htmlFor="formNama">Nama</label>
                                    <input
                                        type="text"
                                        id="formNama"
                                        autoFocus
                                        onChange={(e) => setNama(e.target.value)}
                                        value={nama}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formNoTelp">No. Telpon</label>
                                    <input
                                        type="text"
                                        id="formNoTelp"
                                        onChange={(e) => setNoTelp(e.target.value)}
                                        value={no_telp}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formAlamat">Alamat</label>
                                    <input
                                        type="text"
                                        id="formAlamat"
                                        onChange={(e) => setAlamat(e.target.value)}
                                        value={alamat}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formUsia">Usia</label>
                                    <input
                                        type="text"
                                        id="formUsia"
                                        onChange={(e) => setUsia(e.target.value)}
                                        value={usia}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formEmail">Email</label>
                                    <input
                                        type="text"
                                        id="formEmail"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPassword">Password</label>
                                    <input
                                        type="password"
                                        id="formPassword"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="formKategoriAudio">ID Kategori Audio</label>
                                    <input
                                        type="text"
                                        id="formKategoriAudio"
                                        onChange={(e) => setIdKategoriAudio(e.target.value)}
                                        value={id_kategori_audio}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formKategoriVideo">ID Kategori Video</label>
                                    <input
                                        type="text"
                                        id="formKategoriVideo"
                                        onChange={(e) => setIdKategoriVideo(e.target.value)}
                                        value={id_kategori_video}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="formAvatar">Avatar</label>
                                    <input
                                        type="file"
                                        id="formAvatar"
                                        accept="image/*"
                                        onChange={handleAvatarChange}
                                    />
                                </div>
                                <button type="submit" className="btn btn-update">Update</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseUpdate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}


                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <p>
                                <strong>Nama:</strong> {nama}
                            </p>
                            <p>
                                <strong>No. Telepon:</strong> {no_telp}
                            </p>
                            <p>
                                <strong>Alamat:</strong> {alamat}
                            </p>
                            <p>
                                <strong>Usia:</strong> {usia}
                            </p>
                            <p>
                                <strong>Email:</strong> {email}
                            </p>
                            <p>
                                <strong>Password:</strong> {password}
                            </p>
                            {/* <p>
                                <strong>ID Kategori Audio:</strong> {id_kategori_audio}
                            </p>
                            <p>
                                <strong>ID Kategori Video:</strong> {id_kategori_video}
                            </p> */}
                            {/* <p>
                                <strong>Avatar:</strong>{" "}
                                <img
                                    src={avatar ? `https://sipbibu.tifpsdku.com/backend/public/uploads/${avatar}` : ""}
                                    alt="Avatar"
                                    style={{ width: "100px", height: "auto" }}
                                />
                            </p> */}
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="card">
                    <div className="card__body">
                        <Table dataSource={dataIbu} columns={columns} />
                    </div>
                </div>
            </div>
        </main>
    );
}
export default Ibu;
