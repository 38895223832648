import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

const KuisionerAntepartum = () => {
    const [totalSkorInternal, setTotalSkorInternal] = useState("");
    const [totalSkorEksternal, setTotalSkorEksternal] = useState("");
    const [hasilKesimpulan, setHasilKesimpulan] = useState("");
    const [dataKuisioner, setDataKuisioner] = useState([]);
    const [ibuNames, setIbuNames] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedKuisioner, setSelectedKuisioner] = useState(null);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureAlert, setFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/semuaHasilAntepartum");
            setDataKuisioner(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchIbuName = async (ibuId) => {
        try {
            const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${ibuId}`);
            const namaIbu = response.data.nama;
            setIbuNames((prevNames) => ({
                ...prevNames,
                [ibuId]: namaIbu,
            }));
        } catch (error) {
            console.error('Error fetching ibu name:', error);
            setIbuNames((prevNames) => ({
                ...prevNames,
                [ibuId]: 'Error',
            }));
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/delete/antepartum/${selectedKuisioner.id}`);
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleShowDelete = (record) => {
        setTotalSkorInternal(record.total_skor_internal);
        setTotalSkorEksternal(record.total_skor_eksternal);
        setHasilKesimpulan(record.hasil_kesimpulan);
        setSelectedKuisioner(record);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setTotalSkorInternal("");
        setTotalSkorEksternal("");
        setHasilKesimpulan("");
        setShowDeleteModal(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        dataKuisioner.forEach((kuisioner) => {
            if (kuisioner.create_by_ibu && !ibuNames[kuisioner.create_by_ibu]) {
                fetchIbuName(kuisioner.create_by_ibu);
            }
        });
    }, [dataKuisioner, ibuNames]);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
        },
        {
            title: 'Total Skor Internal',
            dataIndex: 'total_skor_internal',
            key: 'total_skor_internal',
            width: '20%',
        },
        {
            title: 'Total Skor Eksternal',
            dataIndex: 'total_skor_eksternal',
            key: 'total_skor_eksternal',
            width: '20%',
        },
        {
            title: 'Hasil Kesimpulan',
            dataIndex: 'hasil_kesimpulan',
            key: 'hasil_kesimpulan',
            width: '30%',
        },
        {
            title: 'Dibuat Oleh',
            dataIndex: 'create_by_ibu',
            key: 'create_by_ibu',
            render: (text) => (ibuNames[text] || 'Loading...'),
            width: '15%',
        },
        {
            title: 'Aksi',
            key: 'aksi',
            render: (text, record) => (
                <Button
                    className="btn btn-danger text-white"
                    style={{ backgroundColor: 'red', borderColor: 'red', color: 'white' }}
                    onClick={() => handleShowDelete(record)}
                >
                    <DeleteOutlined />
                </Button>
            ),
            width: '10%',
        },
    ];

    return (
        <main className="main-content">
            <div>
                {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}

                <h2>Kuisioner Antepartum</h2>
                <Table
                    columns={columns}
                    dataSource={dataKuisioner}
                    className="table"
                    rowKey="id"
                />
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <p>
                                <strong>Total Skor Internal:</strong> {totalSkorInternal}
                            </p>
                            <p>
                                <strong>Total Skor Eksternal:</strong> {totalSkorEksternal}
                            </p>
                            <p>
                                <strong>Hasil Kesimpulan:</strong> {hasilKesimpulan}
                            </p>
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default KuisionerAntepartum;
