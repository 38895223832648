import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './HasilQuiz.css';

function HasilQuiz({ id }) {
  const [hasil, setHasil] = useState(null);
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [articles, setArticles] = useState([]);
  const [psychologists, setPsychologists] = useState([]);
  const [selectedPsychologist, setSelectedPsychologist] = useState(null);
  const [selectedSertifikat, setSelectedSertifikat] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const responseHasil = await axios.get('https://sipbibu.tifpsdku.com/backend/public/simpanHasil');
        setHasil(responseHasil.data);

        // Data video dari YouTube
        const videos = [
          { id: 1, video_terapis: 'o0bcQUBbJbw', judul: 'Video Edukasi Ibu Hamil', sumber: 'YouTube' },
          { id: 2, video_terapis: '9SQwSqAQ9UQ', judul: 'Tehnik Melahirkan Anak Secara Alami', sumber: 'YouTube' },
          { id: 3, video_terapis: 'c47ONTAvo3E', judul: 'Cara Mengatasi Baby Blues Setelah Melahirkan', sumber: 'YouTube' },
          { id: 4, video_terapis: 'kVnXDE94qBI', judul: 'Hilangkan Stress dengan Gerakan Yoga | Kata Dokter', sumber: 'YouTube' },
          { id: 5, video_terapis: 'FGUyO9y4izo', judul: 'Tips Ampuh Atasi Stress Saat Hamil, Bunda Wajib Coba', sumber: 'YouTube' }
        ];

        // Data audio dari YouTube
        const audios = [
          { id: 1, audio_terapis: 'RnIyb1yVs7Q', judul: 'Panduan Relaksasi Kehamilan - Hypnobirthing', sumber: 'YouTube' },
          { id: 2, audio_terapis: 'RAvD_ylkFcA', judul: 'Afirmasi Kehamilan dan Persalinan (Untuk Muslim) - Tanpa Musik', sumber: 'YouTube' },
          { id: 3, audio_terapis: '4ffr26sUTLI', judul: 'Dengarkan Ini untuk Mengatasi Gangguan Kecemasan Berlebih (Anxiety) | Audio Therapy', sumber: 'YouTube' },
          { id: 4, audio_terapis: 'ZpDRIOYWtCs', judul: 'Afirmasi Positif untuk Hamil Sehat bagi Bumil | Relaksasi Kesehatan Ibu Hamil', sumber: 'YouTube' },
          { id: 5, audio_terapis: 'mxokVTWd7zQ', judul: 'MEMBANGUN KELUARGA YANG BAHAGIA ???? CARI TAU RESEPNYA DISINI | Motivasi Merry | Merry Riana', sumber: 'YouTube' }
        ];

        const randomVideos = getRandomItems(videos, 3);
        const randomAudios = getRandomItems(audios, 3);

        setVideo(randomVideos);
        setAudio(randomAudios);

        const responseArtikel = await axios.get('https://sipbibu.tifpsdku.com/backend/public/artikel');
        const articles = responseArtikel.data.data_artikel;

        const responsePsikolog = await axios.get('https://sipbibu.tifpsdku.com/backend/public/data-psikolog');
        const psychologists = responsePsikolog.data.data_psikolog;

        const randomArticles = getRandomItems(articles, 3);
        const randomPsychologists = getRandomItems(psychologists, 3);

        setArticles(randomArticles);
        setPsychologists(randomPsychologists);
      } catch (error) {
        console.error('Gagal mengambil data:', error.message);
        setError(error.message);
      }
    }

    fetchData();
  }, []);

  function getRandomItems(array, count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }

  function getScoreCardClass() {
    if (hasil && hasil.total_skor > 12) {
      return 'result-card-epds-skor red';
    } else {
      return 'result-card-epds-skor';
    }
  }

  const handlePsychologistClick = (psychologist) => {
    setSelectedPsychologist(psychologist);
  };

  const handleCloseModal = () => {
    setSelectedPsychologist(null);
  };

  const handleSertifikatClick = (sertifikat) => {
    setSelectedSertifikat(sertifikat);
  };

  const handleCloseSertifikatModal = () => {
    setSelectedSertifikat(null);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className='hasil-tes'>
      <div className="hasil-tes-nama">
        <h1>Hasil tes yang anda kerjakan</h1>
      </div>
      {error ? (
        <div className='error-message'>
          <p>{error}</p>
        </div>
      ) : (
        <>
          {hasil ? (
            <div className='result-container'>
              <div className={getScoreCardClass()}>
                <h1>Total Score Tes EPDS Anda </h1>
                <h2>{hasil.total_skor}</h2>
              </div>
              <div className='result-cards-row'>
                <div className="result-card-epds">
                  <h3><i className="fa-solid fa-brain"></i> Kesimpulan hasil tes</h3>
                  <hr />
                  <p>{hasil.hasil_kesimpulan}</p>
                </div>
                <div className="warning-card">
                  <h1><i className="fa-solid fa-circle-exclamation"></i> Peringatan</h1>
                  <hr />
                  <p>Jika Anda merasa tidak yakin dengan hasil kuisioner ini atau mengalami gejala yang parah, kami sangat menyarankan Anda untuk segera menghubungi ahli kesehatan atau rumah sakit terdekat ( NOMOR DARURAT 112 ).</p>
                  <p>Kesehatan Anda sangat penting, dan mendapatkan bantuan profesional adalah langkah terbaik yang bisa Anda ambil untuk memastikan kesejahteraan Anda dan bayi Anda.</p>
                </div>
              </div>
            </div>
          ) : (
            <p>Tidak ada data hasil kuisioner.</p>
          )}

          {/* Tampilkan Psikolog Terbaru */}
          {psychologists.length > 0 && (
            <div className="judul-psikolog-quiz-epds">
              <div className="intro-psikolog-hasil">
                <h1>Hubungi Psikolog</h1>
                <p>Hubungi dan sampaikan keluhanmu sekarang</p>
                <hr />
              </div>
              <div className="terapis-container">
                {psychologists.map((psikolog) => (
                  <div key={psikolog.id} className="terapis-card">
                    <div className="terapis-card-image">
                      <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${psikolog.photo_profile}`} alt={psikolog.nama} className='terapis-image' />
                    </div>
                    <div className="terapis-info">
                      <h2 className="terapis-nama">{psikolog.nama}</h2>
                      <p className="terapis-pengalaman">{psikolog.pengalaman} tahun pengalaman</p>
                      <button className="action-button-terapis" onClick={() => handlePsychologistClick(psikolog)}>Lihat profile</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

         {/* Modal untuk Psikolog */}
         {selectedPsychologist && (
            <div className="modal-terapis-info">
              <div className="modal-content-terapis">
                <span className="close-button-terapis" onClick={handleCloseModal}>&times;</span>
                <div className="modal-header-terapis">
                  <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${selectedPsychologist.photo_profile}`} alt={selectedPsychologist.nama} className='modal-image-terapis' />
                  <div className="modal-info-terapis">
                    <h2>{selectedPsychologist.nama}</h2>
                    <p><strong>Alamat :</strong> {selectedPsychologist.alamat}</p>
                    <p><strong>Kelamin :</strong> {selectedPsychologist.kelamin}</p>
                    <p><strong>Pengalaman :</strong> {selectedPsychologist.pengalaman}</p>
                    <p><strong>Sertifikat :</strong> <span className="sertifikat-link" onClick={() => handleSertifikatClick(selectedPsychologist.sertifikat)}>Lihat Sertifikat</span></p>
                    <a href="https://wa.me/6285727609498" target="_blank" rel="noopener noreferrer">
                      <button className="whatsapp-button">Hubungi di WhatsApp</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

      {selectedSertifikat && (
            <div className="modal-terapis-info" onClick={handleCloseSertifikatModal}>
              <div className="modal-content-terapis">
                <span className="close-button-terapis" onClick={handleCloseSertifikatModal}>&times;</span>
                <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${selectedSertifikat}`} alt="Sertifikat" className='full-sertifikat-terapis' />
              </div>
            </div>
          )}

          {/* Tampilkan Artikel Terbaru */}
          {articles.length > 0 && (
            <div className="container-rubrik-quiz-epds">
              <div className="intro-artikel-hasil">
                <h1>Baca Tentang Kesehatan</h1>
                <p>Tips & info tentang merawat kesehatan ibu</p>
                <hr />
              </div>
              <div className="row-rubrik">
                {articles.map((artikel) => (
                  <div className="col-rubrik" key={artikel.id}>
                    <Link to={`/Isi_Rubrik/${artikel.id}`} className="card-link-rubrik">
                      <div className="card-rubrik">
                        <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${artikel.cover}`} alt="cover" className="card-img-rubrik" />
                        <div className="card-body-rubrik">
                          <h5 className="card-title-rubrik">{artikel.kategori}</h5>
                          <p className="card-subtitle-rubrik">{artikel.judul}</p>
                          <p className="card-text-rubrik">{truncateText(artikel.isi, 100)}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Tampilkan Video Terbaru */}
          {video.length > 0 && (
            <div className="title-video">
              <div className="intro-video-hasil">
                <h1>Tonton Tentang Kesehatan Ibu dan Bayi</h1>
                <p>Video tips & informasi seputar kesehatan ibu dan bayi</p>
                <hr />
              </div>
              <div className="video-terapis-container-list">
                {video.map((videoItem) => (
                  <div key={videoItem.id} className="video-terapis-item">
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${videoItem.video_terapis}`}
                      title={videoItem.judul}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <h2>{videoItem.judul}</h2>
                    <p>Sumber : {videoItem.sumber}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Tampilkan Audio Terbaru */}
          {audio.length > 0 && (
            <div className="title-audio">
              <div className="intro-audio-hasil">
                <h1>Mengatasi Stres Saat Hamil</h1>
                <p>Tips & trik melalui audio tentang cara mengelola stres</p>
                <hr />
              </div>
              <div className="audio-terapis-container-list">
                {audio.map((audioItem) => (
                  <div key={audioItem.id} className="audio-terapis-item">
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${audioItem.audio_terapis}`}
                      title={audioItem.judul}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <h2>{audioItem.judul}</h2>
                    <p>Sumber : {audioItem.sumber}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HasilQuiz;
