import React from 'react';
import about_us_1_1 from "../components/Assets/about_us_1_1.png";
import about_us_2_2 from "../components/Assets/about_us_2_2.png";
import about_us_3_3 from "../components/Assets/about_us_3_3.png";
import './About_Us.css';

export const About_Us = () => {
  return (
    <div className="about-us-container">
        <div className="about-us-intro">
           <h1>Tentang Kami</h1>
           <p>"Dengan semangat dan komitmen yang kuat, kami bertekad untuk menjadi mitra terbaik bagi para ibu di Indonesia dalam menjaga kesehatan mental mereka"</p>
        </div>
      <section className="about-section">
        <div className="about-text-container">
          <h2 className="about-heading"><i className="fa-solid fa-quote-right"></i> Tentang Kami</h2>
          <p className="about-paragraph">Selamat datang di SIPBIBU, platform digital yang didedikasikan untuk mendukung kesehatan mental ibu di Indonesia. Kami memahami bahwa masa setelah melahirkan adalah periode yang penuh tantangan dan perubahan emosional. Oleh karena itu, kami hadir untuk memberikan solusi dalam pencegahan dan penanganan baby blues pada ibu. Dengan berbagai fitur yang kami sediakan, seperti kuesioner, video edukasi, konsultasi online, dan forum diskusi ibu, kami berharap dapat menjadi sahabat terpercaya para ibu dalam menjaga kesehatan mental mereka.</p>
        </div>
        <div className="about-image-container">
          <img src={about_us_1_1} alt="Tentang Kami" />
        </div>
      </section>

      <section className="about-section">
        <div className="about-image-container">
          <img src={about_us_2_2} alt="Visi" />
        </div>
        <div className="about-text-container">
          <h2 className="about-heading"><i className="fa-solid fa-quote-right"></i> Visi</h2>
          <p className="about-paragraph">Menjadi platform terdepan di Indonesia dalam pencegahan dan penanganan baby blues, serta meningkatkan kesejahteraan mental ibu sebelum maupun setelah melahirkan.</p>
        </div>
      </section>

      <section className="about-section">
        <div className="about-image-container">
          <img src={about_us_3_3} alt="Misi" />
        </div>
        <div className="about-text-container">
          <h2 className="about-heading"><i className="fa-solid fa-quote-right"></i> Misi</h2>
          <ul className="about-list">
            <li><strong>Memberikan Edukasi :</strong> Menyediakan informasi dan sumber daya yang mudah diakses mengenai baby blues dan kesehatan mental ibu melalui video edukasi dan artikel informatif.</li>
            <li><strong>Menyediakan Dukungan :</strong> Memfasilitasi konsultasi online dengan ahli kesehatan mental yang berpengalaman untuk memberikan dukungan dan solusi yang tepat bagi ibu yang mengalami baby blues.</li>
            <li><strong>Meningkatkan Kesadaran :</strong> Mengadakan kampanye dan kegiatan untuk meningkatkan kesadaran masyarakat tentang pentingnya kesehatan mental ibu setelah melahirkan dan cara-cara pencegahannya.</li>
            <li><strong>Mengurangi Angka Depresi :</strong> Berkontribusi dalam upaya nasional untuk mengurangi angka baby blues dan depresi postpartum di Indonesia melalui pendekatan yang holistik dan berkelanjutan.</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default About_Us;
