import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Psikolog.css';

export const Psikolog = () => {
  const [terapis, setTerapis] = useState([]);
  const [selectedTerapis, setSelectedTerapis] = useState(null);
  const [selectedSertifikat, setSelectedSertifikat] = useState(null);

  useEffect(() => {
    // Mengambil data psikolog dari backend
    axios.get('https://sipbibu.tifpsdku.com/backend/public/data-psikolog')
      .then(response => {
        // Pastikan response.data.data_psikolog ada dan merupakan array
        const allTerapis = response.data.data_psikolog || [];

        // Melakukan pengacakan (randomization) terhadap data psikolog
        const shuffledTerapis = allTerapis.sort(() => 0.5 - Math.random());

        // Mengambil 4 data psikolog yang teracak
        const selectedTerapis = shuffledTerapis.slice(0, 3);

        // Menyimpan 4 data psikolog yang teracak untuk ditampilkan
        setTerapis(selectedTerapis);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleTerapisClick = (terapis) => {
    setSelectedTerapis(terapis);
  };

  const handleCloseModal = () => {
    setSelectedTerapis(null);
  };

  const handleSertifikatClick = (sertifikat) => {
    setSelectedSertifikat(sertifikat);
  };

  const handleCloseSertifikatModal = () => {
    setSelectedSertifikat(null);
  };

  return (
    <div>
      <div className="terapis">
        <div className="terapis-info-beranda">
        <h1>Psikolog yang Tepat untuk Anda</h1>
        <p>Temukan psikolog yang paling sesuai dengan kebutuhan Anda dan mulailah perjalanan kesehatan mental yang lebih baik</p>
      </div>
      </div>
      <div className="terapis-container">
        {terapis.map(terapis => (
          <div key={terapis.id} className="terapis-card" onClick={() => handleTerapisClick(terapis)}>
            <div className="terapis-card-image">
              <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${terapis.photo_profile}`} alt={terapis.nama} className='terapis-image' />
            </div>
            <div className="terapis-info">
              <h2 className="terapis-nama">{terapis.nama}</h2>
              <p className="terapis-pengalaman">{terapis.pengalaman} tahun pengalaman</p>
              <button className="action-button-terapis">Lihat profile</button>
            </div>
          </div>
        ))}
      </div>

      {selectedTerapis && (
        <div className="modal-terapis-info">
          <div className="modal-content-terapis">
            <span className="close-button-terapis" onClick={handleCloseModal}>&times;</span>
            <div className="modal-header-terapis">
              <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${selectedTerapis.photo_profile}`} alt={selectedTerapis.nama} className='modal-image-terapis' />
              <div className="modal-info-terapis">
                <h2>{selectedTerapis.nama}</h2>
                <p><strong>Alamat :</strong> {selectedTerapis.alamat}</p>
                <p><strong>Kelamin :</strong> {selectedTerapis.kelamin}</p>
                <p><strong>Pengalaman :</strong> {selectedTerapis.pengalaman}</p>
                <p><strong>Sertifikat :</strong> <span className="sertifikat-link" onClick={() => handleSertifikatClick(selectedTerapis.sertifikat)}>Lihat Sertifikat</span></p>
                <a href="https://wa.me/6285727609498" target="_blank" rel="noopener noreferrer">
                  <button className="whatsapp-button">Hubungi di WhatsApp</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedSertifikat && (
        <div className="modal-terapis-info" onClick={handleCloseSertifikatModal}>
          <div className="modal-content-terapis">
            <span className="close-button-terapis" onClick={handleCloseSertifikatModal}>&times;</span>
            <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${selectedSertifikat}`} alt="Sertifikat" className='full-sertifikat-terapis' />
          </div>
        </div>
      )}
    </div>
  );
};

export default Psikolog;
