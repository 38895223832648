import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Alert } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Admin = () => {
    const [id, setId] = useState("");
    const [nama, setNama] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [failureAlert, setFailureAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failureMessage, setFailureMessage] = useState("");

    const navigate = useNavigate();

    const [dataAdmin, setDataAdmin] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sipbibu.tifpsdku.com/backend/public/data-admin/");
            setDataAdmin(response.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            const putData = await axios.put(
                `https://sipbibu.tifpsdku.com/backend/public/update/data-admin/${id}`,
                {
                    nama: nama,
                    email: email,
                    password: password,
                }
            );
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil diubah.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseUpdate();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat mengubah data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        try {
            await axios.post(
                'https://sipbibu.tifpsdku.com/backend/public/data-admin/',
                {
                    nama: nama,
                    email: email,
                    password: password,
                }
            );

            navigate("/admin/data-admin");
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil ditambahkan.");
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseCreate();
            fetchData();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menambahkan data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
            console.error("Error:", error);
        }
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        try {
            const deleteData = await axios.delete(
                `https://sipbibu.tifpsdku.com/backend/public/delete/data-admin/${id}`
            );
            setSuccessAlert(true);
            setSuccessMessage("Data berhasil dihapus.");
            fetchData();
            setTimeout(() => {
                setSuccessAlert(false);
                setSuccessMessage("");
            }, 3000);
            handleCloseDelete();
        } catch (error) {
            setFailureAlert(true);
            setFailureMessage("Terjadi kesalahan saat menghapus data.");
            setTimeout(() => {
                setFailureAlert(false);
                setFailureMessage("");
            }, 3000);
        }
    };

    const handleShowUpdate = (data) => {
        setId(data.id);
        setNama(data.nama);
        setEmail(data.email);
        setPassword(data.password);
        setShowUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setId("");
        setNama("");
        setEmail("");
        setPassword("");
        setShowUpdateModal(false);
    };

    const handleShowCreate = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreate = () => {
        setNama("");
        setEmail("");
        setPassword("");
        setShowCreateModal(false);
    };

    const handleShowDelete = (data) => {
        setId(data.id);
        setNama(data.nama);
        setEmail(data.email);
        setPassword(data.password);
        setShowDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setId("");
        setNama("");
        setEmail("");
        setPassword("");
        setShowDeleteModal(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nama',
            dataIndex: 'nama',
            key: 'nama',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: "Aksi",
            key: "action",
            render: (text, record) => (
                <span>
                    <Button
                        className="btn btn-warning text-black me-2"
                        style={{ backgroundColor: 'yellow', borderColor: 'yellow', color: 'black', height: '45px' }}
                        onClick={() => handleShowUpdate(record)}
                    >
                        <EditOutlined />
                        </Button>
                    <Button
                        className="btn btn-danger text-white"
                        style={{ backgroundColor: 'red', borderColor: 'red', color: 'white', height: '45px' }}
                        onClick={() => handleShowDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>

                </span>
            ),
        },
    ];

    return (
        <main className="main-content">
            <div>
                {successAlert && (
                    <Alert
                        message="Sukses"
                        description={successMessage}
                        type="success"
                        onClose={() => setSuccessAlert(false)}
                        showIcon
                    />
                )}

                {failureAlert && (
                    <Alert
                        message="Gagal"
                        description={failureMessage}
                        type="error"
                        onClose={() => setFailureAlert(false)}
                        showIcon
                    />
                )}

                <h2 className="page-header">Data Admin</h2>
                <Button
                    type="primary"
                    className="mb-3"
                    style={{ backgroundColor: '#007bff', height: '45px', fontFamily: 'poppins', width: '150px', borderRadius: '24px', marginTop: '10px', fontSize: '16px' }}
                    onClick={handleShowCreate}
                >
                    Tambah Data
                </Button>

                {/* Modal CREATE */}
                {showCreateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseCreate}>&times;</span>
                            <h2>Form Tambah Data Admin</h2>
                            <form onSubmit={handleCreate}>
                                <div className="form-group">
                                    <label htmlFor="formNamaCreate">Nama</label>
                                    <input
                                        type="text"
                                        id="formNamaCreate"
                                        autoFocus
                                        onChange={(e) => setNama(e.target.value)}
                                        value={nama}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formEmailCreate">Email</label>
                                    <input
                                        type="text"
                                        id="formEmailCreate"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPasswordCreate">Password</label>
                                    <input
                                        type="password"
                                        id="formPasswordCreate"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Tambah</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseCreate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal EDIT */}
                {showUpdateModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseUpdate}>&times;</span>
                            <h2>Form Update Data Admin</h2>
                            <form onSubmit={handleUpdate}>
                                <div className="form-group">
                                    <label htmlFor="formNama">Nama</label>
                                    <input
                                        type="text"
                                        id="formNama"
                                        autoFocus
                                        onChange={(e) => setNama(e.target.value)}
                                        value={nama}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formEmail">Email</label>
                                    <input
                                        type="text"
                                        id="formEmail"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formPassword">Password</label>
                                    <input
                                        type="password"
                                        id="formPassword"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                                <button type="submit" className="btn btn-update">Update</button>
                                <button type="button" className="btn btn-close" onClick={handleCloseUpdate}>Close</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Modal DELETE */}
                {showDeleteModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDelete}>&times;</span>
                            <h2>Apakah Anda yakin menghapus data ini?</h2>
                            <p>
                                <strong>Nama:</strong> {nama}
                            </p>
                            <p>
                                <strong>Email:</strong> {email}
                            </p>
                            <p>
                                <strong>Password:</strong> {password}
                            </p>
                            <div className="form-group">
                                <button className="btn btn-delete" onClick={handleDelete}>Hapus</button>
                                <button className="btn btn-close" onClick={handleCloseDelete}>Batal</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="card">
                    <div className="card__body">
                        <Table dataSource={dataAdmin} columns={columns} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Admin;
