import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import loginibu_img from "../components/Assets/loginibu_img.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/beranda");
    } else if (localStorage.getItem("tokenAdmin") && localStorage.getItem("userTypeAdmin") === "admin") {
      Swal.fire({
        title: "Anda sudah login",
        icon: "info",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate("/admin");
      });
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios
      .post("https://sipbibu.tifpsdku.com/backend/public/api/login", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.id);
          localStorage.setItem("namaIbu", response.data.nama);
          localStorage.setItem("noTelpIbu", response.data.no_telp);
          localStorage.setItem("alamatIbu", response.data.alamat);
          localStorage.setItem("usiaIbu", response.data.usia);
          localStorage.setItem("emailIbu", response.data.email);
          localStorage.setItem("avatarUrl", response.data.avatar);
          Swal.fire({
            title: "Success!",
            text: "Login successful!",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            navigate("/beranda");
          });
        } else {
          attemptAdminLogin();
        }
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
        attemptAdminLogin();
      });
  };

  const attemptAdminLogin = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios
      .post("https://sipbibu.tifpsdku.com/backend/public/api/loginadmin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("tokenAdmin", response.data.token);
          localStorage.setItem("namaAdmin", response.data.nama);
          localStorage.setItem("idAdmin", response.data.id);
          localStorage.setItem("userTypeAdmin", "admin");
          Swal.fire({
            title: "Success!",
            text: "Admin login successful!",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            navigate("/admin");
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.error,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to login. Please try again later.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="login-container">
      <div className="cover-ibu">
        <h1>Login</h1>
        <img src={loginibu_img} alt='' />
        <form onSubmit={handleSubmit}>
          <p>Email</p>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
            className="input-field-ibu"
          />
          <p>Password</p>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Your Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="input-field-ibu"
            />
            <button
              type="button"
              onClick={handleShowPasswordToggle}
              className="show-password-btn"
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
          <button type="submit" className="login-btn-ibu">
            Login
          </button>
          <p className="error-message">
            {/* Menampilkan pesan kesalahan */}
          </p>
        </form>
        <div className="alt-login">
          {/* Your alternative login buttons here */}
        </div>
        <p className="register-link">
          Don't have an account? <a href="/register">Register</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
