import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PilihanQuiz.css';
import tes_dua from '../Assets/tes_dua.jpg';
import tes_dua_dua from '../Assets/tes_dua_dua.jpg';
import tes_up from '../Assets/tes_up.png';
import tes_satu from '../Assets/tes_satu.png';

const PilihanQuiz = () => {
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const [showAlert, setShowAlert] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState('');

  useEffect(() => {
    titleRef.current.focus();
    window.scrollTo(0, 0);  // Menggulung halaman ke atas saat komponen dimuat
  }, []);

  const handleButtonClick = (path) => {
    setSelectedQuiz(path);
    setShowConfirmation(true);
  };

  const confirmNavigation = () => {
    setShowConfirmation(false);
    if (selectedQuiz) {
      navigate(selectedQuiz);
    }
  };

  return (
    <div className='pilihan-quiz'>
      <div className="intro-card">
        <h1 ref={titleRef} tabIndex={-1}>Langkah awal yang baik !</h1>
        <p>Kenali diri ibu lebih dalam dan pilihlah tes yang sesuai untuk memahami kondisi mental selama masa kehamilan. Baca informasi tes dahulu sebelum mengambil tes ya</p>
      </div>
      {/* {showAlert && (
        <div className="popup-pilihan">
          <div className="popup-pilihan-first">
            <h1>PERHATIKAN!</h1>
            <h3>Informasi Tes Untuk Ibu</h3>
            <p>Tes ini adalah Tes untuk mengidentifikasi adanya kemungkinan seorang ibu mengalami sindrom baby blues sebelum dan pasca melahirkan menggunakan pertanyaan model suryani...</p>
            <p>Jadi sebelum mengambil tes tolong untuk membaca terlebih dahulu informasi yang tertera dipilihan tes.</p>
            <button onClick={() => setShowAlert(false)}>Saya Mengerti</button>
          </div>
        </div>
      )} */}
      {showConfirmation && (
        <div className="popup-tes">
          <div className="popup-pilihan-tes">
            <h1><i class="fa-solid fa-circle-exclamation"></i> KONFIRMASI</h1>
            <h2>1. Test ini TIDAK ditujukan untuk mendiagnosis gangguan psikologis, tetapi hanya untuk memeriksa risiko atau kemungkinan saja</h2>
            <h2>2. Apabila anda mengalami gejala yang mengganggu, segeralah berkonsultasi ke psikolog atau ahli kesehatan terdekat</h2>
            <p>Apakah Anda yakin ingin mengambil tes ini?</p>
            <div>
              <button onClick={confirmNavigation}>Ya</button>
              <button onClick={() => setShowConfirmation(false)}>Tidak</button>
            </div>
          </div>
        </div>
      )}
      <div className="pilihan-container">
        <div className="pilihan-card">
          <img src={tes_dua_dua} alt='' className="card-image-left" />
          <div className="card-content">
            <h1>Tes Pengukuran Risiko Sindrom Baby Blues Sebelum Melahirkan</h1>
            <p>Tes ini adalah tes untuk mengukur risiko seorang ibu mengalami sindrom baby blues, kuis ini dikerjakan sebelum ibu melahirkan. Tes ini menggunakan pertanyaan model suryani, 
              yaitu model pertanyaan yang dikemukakan oleh Suryani Manurung M.Kep. ,Sp.Mat dari Fakultas Ilmu Keperawatan, Universitas Indonesia.</p>
            <button onClick={() => handleButtonClick('/AntepartumQuiz')}>
              Ambil Tes
            </button>
          </div>
        </div>
        <div className="pilihan-card-info">
          <h1><i className="fa-solid fa-lightbulb"></i> Informasi Tes</h1>
          <p>1. Pada tes ini terdapat 24 pertanyaan yang harus dijawab</p>
          <p>2. Pertanyaan nomor 1 sampai 8 terkait faktor internal atau faktor dari diri sendiri seperti pikiran, kesehatan, atau mental yang mempengaruhi ibu</p>
          <p>3. Pertanyaan nomor 9 sampai 24 terkait faktor eksternal atau faktor sosial dari luar seperti lingkungan, keluarga atau orangtua yang ada disekitar ibu</p>
        </div>
        <div className="pilihan-card">
          <img src={tes_dua} alt='' className="card-image-right" />
          <div className="card-content-right">
            <h1>Tes Pengukuran Baby Blues Pasca Melahirkan</h1>
            <p>Tes ini adalah Tes untuk mengidentifikasi adanya kemungkinan seorang ibu mengalami sindrom baby blues pasca melahirkan menggunakan pertanyaan model suryani, 
              yaitu model pertanyaan yang dikemukakan oleh Suryani Manurung M.Kep. ,Sp.Mat dari Fakultas Ilmu Keperawatan, Universitas Indonesia.</p>
            <div className="button-container-right">
              <button onClick={() => handleButtonClick('/QuizForm_2')}>
                Ambil Tes
              </button>
            </div>
          </div>
        </div>
        <div className="pilihan-card-info">
          <h1><i className="fa-solid fa-lightbulb"></i> Informasi Tes</h1>
          <p>1. Pada tes ini terdapat 32 pertanyaan yang harus dijawab</p>
          <p>2. Pertanyaan nomor 1 sampai 24 terkait faktor internal atau faktor dari diri sendiri seperti pikiran, kesehatan, atau mental yang mempengaruhi ibu</p>
          <p>3. Pertanyaan nomor 25 sampai 32 terkait faktor eksternal atau faktor sosial dari luar seperti lingkungan, keluarga atau orangtua yang ada disekitar ibu</p>
          <p>4. Jika pada tes ini ibu teridentifikasi kemungkinan mengalami baby blues, maka akan ada tes lanjutan pada halaman hasil tes yaitu tes Depresi (EPDS)</p>
        </div>
        <div className="pilihan-card">
          <img src={tes_satu} alt='' className="card-image-right" />
          <div className="card-content-right">
            <h1>Tes Edinburgh Postnatal Depression Scale</h1>
            <p>Tes ini adalah adalah tes yang dikembangkan untuk membantu mengidentifikasi kemungkinan gejala depresi pada periode pasca melahirkan. Tes ini memiliki sensitivitas dan spesifisitas yang memadai 
              untuk mengidentifikasi gejala depresi pada masa sebelum melahirkan dan berguna dalam mengidentifikasi gejala kecemasan.</p>
            <div className="button-container-right">
              <button onClick={() => handleButtonClick('/QuizForm')}>
                Ambil Tes
              </button>
            </div>
          </div>
        </div>
        <div className="pilihan-card-info">
          <h1><i className="fa-solid fa-lightbulb"></i> Informasi Tes</h1>
          <p>1. Pada tes ini terdapat 10 pertanyaan yang harus dijawab</p>
          <p>2. Anda memilih jawaban yang paling mendekati dengan apa yang anda rasakan selama tujuh hari sebelumnya, tidak hanya saat anda mengambil tes ini.</p>
          <p>3. Skala hasil ini dapat digunakan pada enam hingga delapan minggu setelah kelahiran atau selama kehamilan.</p>
        </div>
        <div className="pilihan-card-warning">
          <h1><i class="fa-solid fa-circle-exclamation"></i> Peringatan</h1>
          <p>Fitur kuisioner ini dirancang untuk membantu mengidentifikasi risiko dan kemungkinan seorang ibu akan mengalami sindrom baby blues dan depsresi. Hasil dari kuisioner ini bukan merupakan diagnosis medis.</p>
          <p>Jika Anda merasa tidak yakin dengan hasil kuisioner ini atau mengalami gejala yang parah, kami sangat menyarankan Anda untuk segera menghubungi ahli kesehatan atau rumah sakit terdekat ( NOMOR DARURAT 112 ).</p>
          <p>Kesehatan Anda sangat penting, dan mendapatkan bantuan profesional adalah langkah terbaik yang bisa Anda ambil untuk memastikan kesejahteraan Anda dan bayi Anda.</p>
        </div>
      </div>
    </div>
  );
};

export default PilihanQuiz;
