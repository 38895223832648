import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './QuizForm_2.css';

function QuizForm_2() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [ibuId, setIbuId] = useState(localStorage.getItem('id'));
  const [hasilKesimpulanInternal, setHasilKesimpulanInternal] = useState('');
  const [hasilKesimpulanEksternal, setHasilKesimpulanEksternal] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuestions();
    const timer = setTimeout(() => {
      setShowAlert(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get('https://sipbibu.tifpsdku.com/backend/public/getQuestions');
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const optionValues = {
    positive: [1, 2, 3, 4, 5],
    negative: [5, 4, 3, 2, 1]
  };

  const getOptionValue = (questionId, option) => {
    const isPositiveStatement = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 16, 32].includes(questionId);
    const values = isPositiveStatement ? optionValues.positive : optionValues.negative;
    return values[option];
  };

  const updateTotalScore = (updatedAnswers) => {
    let totalSkorInternal = 0;
    let totalSkorEksternal = 0;

    for (const questionId in updatedAnswers) {
      const value = updatedAnswers[questionId];
      const optionValue = getOptionValue(parseInt(questionId), value);

      if (parseInt(questionId) <= 24) {
        totalSkorInternal += optionValue;
      } else {
        totalSkorEksternal += optionValue;
      }
    }

    // Tentukan hasil kesimpulan internal
    const hasilInternal = (totalSkorInternal >= 53) ? 'Hasil kesimpulan internal positif' : 'Hasil kesimpulan internal negatif';
    setHasilKesimpulanInternal(hasilInternal);

    // Tentukan hasil kesimpulan eksternal
    const hasilEksternal = (totalSkorEksternal >= 19) ? 'Hasil kesimpulan eksternal positif' : 'Hasil kesimpulan eksternal negatif';
    setHasilKesimpulanEksternal(hasilEksternal);

    return { totalSkorInternal, totalSkorEksternal };
  };

  const handleOptionClick = (questionId, value) => {
    const updatedAnswers = { ...answers, [questionId]: value };
    setAnswers(updatedAnswers);
    updateTotalScore(updatedAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const idIbu = ibuId || localStorage.getItem('id');

    if (!idIbu) {
      console.error('ID ibu tidak ditemukan.');
      return;
    }

    if (!showConfirmation && currentQuestionIndex >= questions.length - 4) {
      setShowConfirmation(true);
      return;
    }

    if (e.target.textContent === 'SUDAH') {
      setShowConfirmation(false);

      const unansweredQuestions = questions.filter(question => !answers.hasOwnProperty(question.id));
      if (unansweredQuestions.length > 0) {
        alert('Anda belum menjawab semua pertanyaan!');
        return;
      }

      try {
        const { totalSkorInternal, totalSkorEksternal } = updateTotalScore(answers);

        const response = await axios.post('https://sipbibu.tifpsdku.com/backend/public/simpanHasil_2', {
          jawaban: answers, // Mengirim answers langsung
          total_skor_internal: totalSkorInternal,
          total_skor_eksternal: totalSkorEksternal,
          create_by_ibu: idIbu
        });

        const { hasil_kesimpulan_internal, hasil_kesimpulan_eksternal } = response.data.hasil_kuisioner;

        setHasilKesimpulanInternal(hasil_kesimpulan_internal);
        setHasilKesimpulanEksternal(hasil_kesimpulan_eksternal);

        navigate('/HasilQuiz_2');
      } catch (error) {
        console.error('Error saving questionnaire results:', error);
      }
    } else {
      setShowConfirmation(false);
    }
  };

  const handleNext = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 4);
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 4);
  };

  return (
    <div className='tes-baby-blues'>
      <div className="tes-nama">
        <h1>Tes Pengukuran Baby Blues Pasca Melahirkan</h1>
      </div>
      <div className="tes-baby-container">
        <div className="tes-baby-soal">
          <form onSubmit={handleSubmit}>
            {/* {showAlert && (
              <div className="popup-dua">
                <div className="popup-content-dua">
                  <h1>PERHATIKAN</h1>
                  <h2>Tata Cara & Informasi Mengerjakan Quiz</h2>
                  <p>1. Bunda memilih dengan klik jawaban yang paling mendekati dengan apa yang ia rasakan selama tujuh hari sebelumnya.</p>
                  <p>2. Ada pertanyaan berjumlah 32 yang harus diisi dengan lengkap dan sesuai perasaan bunda sekarang.</p>
                  <p>3. Pertanyaan nomor 1 sampai 24 adalah terkait faktor internal atau faktor dari diri sendiri yang mempengaruhi bunda.</p>
                  <p>4. Pertanyaan nomor 25 sampai 32 adalah terkait faktor eksternal atau faktor dari luar yang mempengaruhi bunda.</p>
                  <p>5. Harus berhati-hati untuk menghindari kemungkinan bunda mendiskusikan jawabannya dengan orang lain.</p>
                  <p>6. Bunda harus mengisi sendiri tes ini, kecuali jika bunda memiliki keterbatasan mobilisasi atau kesulitan membaca.</p>
                  <button onClick={() => setShowAlert(false)}>Saya Mengerti</button>
                </div>
              </div>
            )} */}
            {showConfirmation && (
              <div className="popup-confirmation">
                <div className="popup-content-confirmation">
                  <h2>KONFIRMASI</h2>
                  <p>Apakah Ibu yakin ingin menyelesaikan quiz?</p>
                  <div>
                    <button onClick={handleSubmit}>SUDAH</button>
                    <button onClick={() => setShowConfirmation(false)}>BELUM</button>
                  </div>
                </div>
              </div>
            )}
            {questions.slice(currentQuestionIndex, currentQuestionIndex + 4).map((question) => (
              <div key={question.id}>
                <p>{question.pernyataan}</p>
                <div>
                  {[0, 1, 2, 3, 4].map((option) => (
                    <button
                      key={option}
                      className="option-btn"
                      style={{ backgroundColor: answers[question.id] === option ? '#EC744A' : '' }}
                      data-value={option}
                      data-group={question.id}
                      onClick={() => handleOptionClick(question.id, option)}
                      type="button"
                    >
                      {['Sangat Sering', 'Sering', 'Biasa', 'Jarang', 'Sangat Jarang'][option]}
                    </button>
                  ))}
                </div>
              </div>
            ))}
            <div className="button-soal-bottom">
              {currentQuestionIndex > 0 && (
                <button onClick={handlePrevious} className="custom-button-previous">Sebelumnya</button>
              )}
              {currentQuestionIndex >= questions.length - 4 && (
                <button onClick={handleSubmit} type="submit" className="custom-button-simpan">Simpan</button>
              )}
              {currentQuestionIndex < questions.length - 4 && (
                <button onClick={handleNext} className="custom-button-next">Selanjutnya</button>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="tes-info-card">
          <h1><i className="fa-solid fa-lightbulb"></i> Informasi Tes</h1>
            <p>1. Pada tes ini terdapat 32 pertanyaan yang harus dijawab</p>
            <p>2. Pertanyaan nomor 1 sampai 24 terkait faktor internal atau faktor dari diri sendiri seperti pikiran, kesehatan, atau mental yang mempengaruhi ibu</p>
            <p>3. Pertanyaan nomor 25 sampai 32 terkait faktor eksternal atau faktor sosial dari luar seperti lingkungan, keluarga atau orangtua yang ada disekitar ibu</p>
            <p>4. Harus berhati-hati untuk menghindari kemungkinan anda mendiskusikan jawabannya dengan orang lain.</p>
            <p>5. Anda harus mengisi sendiri tes ini, kecuali jika anda memiliki keterbatasan mobilisasi atau kesulitan membaca.</p>
          </div>
    </div>
  );
}

export default QuizForm_2;
