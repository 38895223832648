import React, { useState, useEffect } from "react";
import "./LoginAdmin.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import login_img from "../components/Assets/login_img.png";

const LoginAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("tokenAdmin") && localStorage.getItem("userTypeAdmin") === "admin") {
      Swal.fire({
        title: "Anda sudah login",
        icon: "info",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate('/admin');
      });
    }
  }, [navigate]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
  
    axios.post("https://sipbibu.tifpsdku.com/backend/public/api/loginadmin", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("tokenAdmin", response.data.token);
        localStorage.setItem("namaAdmin", response.data.nama); // Simpan nama admin ke localStorage
        localStorage.setItem("idAdmin", response.data.id);
        localStorage.setItem("userTypeAdmin", "admin");
        console.log("Nama admin disimpan di localStorage:", response.data.nama); // Tambahkan log ini
        Swal.fire({
          title: "Success!",
          text: "Login successful!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate('/admin');
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.error,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((error) => {
      console.error("There has been a problem with your fetch operation:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to login. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
  };

  return (
    <div className="login-container">
      <div className="cover-admin">
        <h1>Login Admin</h1>
        <img src={login_img} alt='' />
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
            className="input-field"
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="input-field"
            />
            <button
              type="button"
              onClick={handleShowPasswordToggle}
              className="show-password-btn"
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
          <button type="submit" className="login-btn-admin">
            Login
          </button>
        </form>
        <div className="alt-login">
          {/* Your alternative login buttons here */}
        </div>
        <p className="register-link">
          Don't have an account? <a href="/registeradmin">Register</a>
        </p>
      </div>
    </div>
  );
};

export default LoginAdmin;
