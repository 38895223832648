import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import './Isi_Rubrik.css';

export const Isi_Rubrik = () => {
  const { id } = useParams(); // Gunakan useParams untuk mendapatkan nilai 'id'
  const [artikel, setArtikel] = useState(null);

  useEffect(() => {
    fetch(`https://sipbibu.tifpsdku.com/backend/public/artikel/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          setArtikel(data.artikel_byid);
        } else {
          console.error('Failed to fetch artikel data');
        }
      })
      .catch(error => {
        console.error('Error fetching artikel data:', error);
      });
  }, [id]); // Gunakan 'id' dari useParams sebagai dependensi useEffect

  if (!artikel) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="judul-penulis-kategori">
        <h1>{artikel.kategori}</h1>
        <h2>{artikel.judul}</h2>
        </div>
        <div className="info-penulis">
        <h3>{artikel.penulis} | {artikel.created_at}</h3>
      </div>
      <div className="container-isi-rubrik">
        <div className="cover-rubrik">
          <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${artikel.cover}`} alt="Cover" />
        </div>
        <div className="konten-rubrik">
          <p>{artikel.pendahuluan}</p>
          <p>{artikel.isi}</p>
          <p>{artikel.penutup}</p>
        </div>
      </div>
    </div>
  );
}

export default Isi_Rubrik;
