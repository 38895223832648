import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from '../Assets/SIPBIBU_Logo_2.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const Navbar = () => {
    const [menu, setMenu] = useState("");
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false); 
    const [namaIbu, setNamaIbu] = useState(""); 
    const [avatarUrl, setAvatarUrl] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        if (loggedInUser) {
            setLoggedIn(true);
            const nama = localStorage.getItem("namaIbu");
            setNamaIbu(nama);
            const avatar = localStorage.getItem("avatarUrl");
            setAvatarUrl(avatar);
        } else {
            setLoggedIn(false);
            setNamaIbu("");
            setAvatarUrl("");
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos;

            setVisible(visible);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, visible]);

    const handleLogout = () => {
        Swal.fire({
            title: 'Logout',
            text: 'Apakah Anda yakin ingin logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Logout',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Berhasil!',
                    'Anda telah berhasil logout.',
                    'success'
                ).then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("namaIbu");
                    localStorage.removeItem("avatarUrl");
                    setLoggedIn(false);
                    setNamaIbu("");
                    setAvatarUrl("");
                    navigate("/login");
                });
            }
        });
    };

    const handleMenuClick = (menuName, path) => {
        setMenu(menuName);
        if (menuName === "Thread" && !loggedIn) {
            Swal.fire({
                title: "Anda belum login",
                text: "Silakan login terlebih dahulu untuk mengakses forum diskusi.",
                icon: "warning",
                confirmButtonText: "Ok",
            }).then(() => {
                navigate("/login");
            });
        } else {
            navigate(path);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleViewProfile = () => {
        navigate('/profile');
        setDropdownOpen(false);
    };

    const handleLogoutClick = () => {
        handleLogout();
        setDropdownOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setDropdownOpen(false);
        }
    };

    const handleDropdownMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleDropdownMouseLeave = () => {
        setDropdownOpen(false);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <div className={`navbar ${visible ? 'show' : 'hide'}`}>
            <div className="nav-logo">
                <img src={logo} alt="Logo" />
            </div>
            <ul className={`nav-menu ${menuOpen ? 'active' : ''}`}>
                <li onClick={() => handleMenuClick("beranda", "/beranda")} className={menu === "beranda" ? 'active' : ''}><Link to='/beranda'>Beranda</Link></li>
                <li onClick={() => handleMenuClick("konsul", "/konsul")} className={menu === "konsul" ? 'active' : ''}><Link to='/konsul'>Konsultasi Online</Link></li>
                <li onClick={() => handleMenuClick("Thread", "/Thread")} className={menu === "Thread" ? 'active' : ''}><Link to='/Thread'>Forum Diskusi Ibu</Link></li>
                <li onClick={() => handleMenuClick("rubriks", "/rubriks")} className={menu === "rubriks" ? 'active' : ''}><Link to='/rubriks'>Rubriks Mom</Link></li>
                <li onClick={() => handleMenuClick("About_Us", "/About_Us")} className={menu === "About_Us" ? 'active' : ''}><Link to='/About_Us'>Tentang Kami</Link></li>
            </ul>
            <div className="nav-right">
                <div className="hamburger" onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {loggedIn ? (
                    <div className="nav-login-cart">
                        <span className='nama-ibu'>{namaIbu}</span>
                        <div
                            className="dropdown"
                            ref={dropdownRef}
                            onMouseEnter={handleDropdownMouseEnter}
                            onMouseLeave={handleDropdownMouseLeave}
                        >
                            <div onClick={toggleDropdown}>
                                {avatarUrl && <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${avatarUrl}`} alt="Avatar" className="avatar" />}
                            </div>
                            {dropdownOpen && (
                                <div className="dropdown-content">
                                    <span onClick={handleViewProfile}>View Profile</span>
                                    <span onClick={handleLogoutClick}>Logout</span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="nav-login-cart">
                        <Link to='/login'>
                            <button>
                                <i className="fa-solid fa-right-to-bracket"></i> Login
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
