import React, { useRef } from 'react';
import Hero from '../components/Hero/Hero';
import Popular from '../components/Popular/Popular';
import Quiz from '../components/Quiz/Quiz';
import Artikel from '../components/Artikel/Artikel';
import Psikolog from '../components/Psikolog/Psikolog';

export const Beranda = () => {
  const quizRef = useRef(null); // Create a ref for Quiz section
  const psikologRef = useRef(null); // Create a ref for Psikolog section

  const scrollToQuiz = () => {
    quizRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to Quiz section
  };

  const scrollToPsikolog = () => {
    psikologRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to Psikolog section
  };

  return (
    <div>
      <Hero scrollToQuiz={scrollToQuiz} scrollToPsikolog={scrollToPsikolog} /> {/* Pass the scroll function to Hero */}
      <Popular />
      <div ref={quizRef}> {/* Attach the ref to Quiz section */}
        <Quiz />
      </div>
      <div ref={psikologRef}> {/* Attach the ref to Psikolog section */}
        <Psikolog />
      </div>
      <Artikel />
    </div>
  );
};

export default Beranda;
