import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Quiz.css';
import Swal from 'sweetalert2';
import icon_tes from '../Assets/icon_tes.png';

export const Quiz = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleStartQuiz = () => {
    if (loggedIn) {
      navigate('/PilihanQuiz');
    } else {
      Swal.fire({
        title: 'Anda belum login!',
        text: 'Silakan login terlebih dahulu untuk memulai tes.',
        icon: 'warning',
        confirmButtonText: 'OK',
        showCancelButton: true,
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    }
  };

  return (
    <div className='quiz-card'>
      <div className='quiz-content'>
        <img src={icon_tes} alt='Icon Tes' className='quiz-icon' />
        <h1><span className='highlight-quiz'>Periksa Cepat</span> dan Dapatkan Langsung Hasilnya</h1>
        <p>Jangan biarkan kesehatan mental menghambat kebahagiaan Anda. Luangkan beberapa menit untuk mengisi tes kami dan dapatkan wawasan berharga tentang kondisi Anda.
        Kesejahteraan emosional Anda adalah prioritas kami.</p>
        <button onClick={handleStartQuiz}>Yuk coba tes</button>
      </div>
    </div>
  );
};

export default Quiz;
