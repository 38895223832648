import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Isi_Video.css';

export const Isi_Video = () => {
  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Array video YouTube dengan judul
  const youtubeVideos = [
    { id: 1, link: 'https://www.youtube.com/embed/o0bcQUBbJbw', judul: 'Video Edukasi Ibu Hamil' },
    { id: 2, link: 'https://www.youtube.com/embed/9SQwSqAQ9UQ', judul: 'Tehnik Melahirkan Anak Secara Alami' },
    { id: 3, link: 'https://www.youtube.com/embed/kVnXDE94qBI', judul: 'Hilangkan Stress dengan Gerakan Yoga | Kata Dokter' },
    { id: 4, link: 'https://www.youtube.com/embed/FGUyO9y4izo', judul: 'Tips Ampuh Atasi Stress Saat Hamil, Bunda Wajib Coba' },
    { id: 5, link: 'https://www.youtube.com/embed/rkwEI_ZWNCs', judul: 'Cemas Berlebihan Dalam Kehamilan' },
    { id: 6, link: 'https://www.youtube.com/embed/4ZAHX-N-AhM', judul: '7 Tips Agar Kehamilanmu Tetap Sehat' },
    { id: 7, link: 'https://www.youtube.com/embed/XKsy2yD4pco', judul: 'TIPS MENJAGA KANDUNGAN KUAT DAN SEHAT - TANYAKAN DOKTER' },
    { id: 8, link: 'https://www.youtube.com/embed/sqxOH1nbP1A', judul: 'Stres saat Hamil? Coba Lakukan Cara Ini!' },
    { id: 9, link: 'https://www.youtube.com/embed/lTOZ0BM_loA', judul: 'Tips 9 Cara Menghilangkan Stres Saat Hamil' },
    { id: 10, link: 'https://www.youtube.com/embed/RY806h6ouLk', judul: 'Cara Jitu Mengatasi Baby Blues Syndrome I Menurut Moms' },
    { id: 11, link: 'https://www.youtube.com/embed/4us7RUEnbA0', judul: 'Inilah 5 Cara Atasi Baby Blues saat Menyusui' },
    { id: 12, link: 'https://www.youtube.com/embed/02-U_2fERPE', judul: 'Gelisah Berlebihan Setelah Melahirkan ? Begini Ternyata Cara Mengatasi Baby Blues' }
  ];

  useEffect(() => {
    // Sementara gunakan link YouTube
    setVideos(youtubeVideos.map(video => ({
      id: video.id,
      video_terapis: video.link,
      judul: video.judul,
      sumber: 'YouTube'
    })));
  }, []);

  // Fungsi untuk meng-handle perubahan pencarian
  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  // Lakukan filter video berdasarkan pencarian
  const filteredVideos = videos.filter(video =>
    video.judul.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className='video-terapis-container-all-isi'>
        <div className="intro-video-beranda">
          <h1>Temukan Edukasi yang Bermanfaat</h1>
          <p>Tempat terbaik untuk para ibu mendapatkan informasi, dukungan, dan edukasi</p>
        </div>
        <div className="search-video">
          <input
            type="text"
            placeholder=" 🔍 Cari video..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="video-terapis-container-list">
        {filteredVideos.map(video => (
          <div key={video.id} className="video-terapis-item">
            <iframe
              title={video.judul}
              width="100%"
              height="200"
              src={video.video_terapis}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h2>{video.judul}</h2>
            <p>Sumber: {video.sumber}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Isi_Video;
