import React from 'react';
import './Hero.css';
import hero_image_5 from '../Assets/hero_image_5.png';

export const Hero = ({ scrollToQuiz, scrollToPsikolog }) => {
  return (
    <div className='hero'>
      <div className='hero-left'>
        <div>
          <h1>Solusi Cepat 👌</h1>
          <h1><span className='highlight-hero'>Cegah dan Tangani</span></h1>
          <h1>Sindrom Baby Blues</h1>
          <p>Tes Sindrom Baby Blues, Chat Psikolog, Sharing Pengalaman, Lihat Video, Audio, ataupun Artikel tentang kesehatan ibu, semua bisa disini yuk buruan coba!</p>
        </div>
        <button onClick={scrollToQuiz}>
          Yuk, Coba Tes Baby Blues
        </button>
        <button onClick={scrollToPsikolog}>
          Hubungi Psikolog
        </button>
      </div>
      <div className='hero-right'>
        <img src={hero_image_5} alt="Hero Image" />
      </div>
    </div>
  );
};

export default Hero;
