import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Login from "./pages/Login";
import LoginAdmin from "./pages/LoginAdmin";
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from "./components/Dashboard";
import Register from "./pages/Register";
import RegisterAdmin from "./pages/RegisterAdmin";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import DataIbu from "./pages/DataIbu";
import DataPsikolog from "./pages/DataPsikolog";
import DataAdmin from "./pages/DataAdmin";
import DataThread from "./pages/DataThread";
import DataReply from "./pages/DataReply";
import Report from "./pages/Report";
import RubriksMom from "./pages/RubriksMom";
import Kuisioner from "./pages/Kuisioner";
import Kuisioner2 from "./pages/Kuisioner2";
import KuisionerAntepartum from "./pages/KuisionerAntepartum";
import Profile from "./pages/Profile";
import Audio from "./pages/Audio";
import Isi_Video from "./pages/Isi_Video";
import Isi_Audio from "./pages/Isi_Audio";
import Video from "./pages/Video";
import UserLayout from "./components/UserLayout";
import { Navbar } from './components/Navbar/Navbar';
import { Beranda } from './pages/Beranda';
import { Rubrik } from './pages/Rubrik';
import { Forum } from './pages/Forum';
import { Terapis } from './pages/Terapis';
import QuizForm from './components/QuizForm/QuizForm';
import QuizForm_2 from './components/QuizForm_2/QuizForm_2';
import PilihanQuiz from './components/PilihanQuiz/PilihanQuiz';
import HasilQuiz from './components/QuizForm/HasilQuiz';
import HasilQuiz_2 from './components/QuizForm_2/HasilQuiz_2';
import Footer from './components/Footer/Footer';
import Isi_Rubrik from "./pages/Isi_Rubrik";
import Thread from "./pages/Thread";
import Isi_Thread from "./pages/Isi_Thread";
import AntepartumQuiz from "./components/Antepartum/AntepartumQuiz";
import HasilAntepartum from "./components/Antepartum/HasilAntepartum";
import GrafikKuis_1 from "./pages/GrafikKuis_1";
import GrafikKuis_2 from "./pages/GrafikKuis_2";
import GrafikKuis_3 from "./pages/GrafikKuis_3";
import About_Us from "./pages/About_Us";

const MainLayout = () => (
  <div className="app">
    <Sidebar />
    <Header />
    <main className="content">
      <Outlet />
    </main>
  </div>
);

const UserLayoutWithNavbarFooter = () => (
  <div>
    <Navbar />
    <Outlet />
    <Footer />
  </div>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/loginadmin" element={<LoginAdmin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/registeradmin" element={<RegisterAdmin />} />
        {/* Rute untuk halaman admin */}
        <Route path="/admin/*" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="data-ibu" element={<DataIbu />} />
          <Route path="data-psikolog" element={<DataPsikolog />} />
          <Route path="data-admin" element={<DataAdmin />} />
          <Route path="artikel" element={<RubriksMom />} />
          <Route path="reset-password" element={<Resetpassword />} />
          <Route path="forgot-password" element={<Forgotpassword />} />
          <Route path="kuisioner" element={<Kuisioner />} />
          <Route path="kuisioner2" element={<Kuisioner2 />} />
          <Route path="kuisionerantepartum" element={<KuisionerAntepartum />} />
          <Route path="audio" element={<Audio />} />
          <Route path="video" element={<Video />} />
          <Route path="data-thread" element={<DataThread />} />
          <Route path="data-reply/:id" element={<DataReply />} />
          <Route path="report" element={<Report />} />
        </Route>
        {/* Rute untuk halaman pengguna */}
        <Route path="/" element={<UserLayoutWithNavbarFooter />}>
          {/* Menggunakan UserLayout */}
          <Route index element={<Navigate to="beranda" />} />
          <Route path="beranda" element={<Beranda />} />
          <Route path="konsul" element={<Terapis />} />
          <Route path="forums" element={<Forum />} />
          <Route path="rubriks" element={<Rubrik />} />
          <Route path="isi_rubrik/:id" element={<Isi_Rubrik />} />
          <Route path="thread/:id" element={<Isi_Thread />} />
          <Route path="isi_video" element={<Isi_Video />} />
          <Route path="isi_audio" element={<Isi_Audio />} />
          <Route path="terapis" element={<Terapis />} />
          <Route path="profile" element={<Profile />} />
          <Route path="QuizForm" element={<QuizForm />} />
          <Route path="QuizForm_2" element={<QuizForm_2 />} />
          <Route path="PilihanQuiz" element={<PilihanQuiz />} />
          <Route path="HasilQuiz" element={<HasilQuiz />} />
          <Route path="HasilQuiz_2" element={<HasilQuiz_2 />} />
          <Route path="AntepartumQuiz" element={<AntepartumQuiz />} />
          <Route path="HasilAntepartum" element={<HasilAntepartum />} />
          <Route path="GrafikKuis_1" element={<GrafikKuis_1 />} />
          <Route path="GrafikKuis_2" element={<GrafikKuis_2 />} />
          <Route path="GrafikKuis_3" element={<GrafikKuis_3 />} />
          <Route path="Thread" element={<Thread />} />
          <Route path="About_Us" element={<About_Us />} />
        </Route>
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
