import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import './GrafikKuis_3.css';

const GrafikKuis_3 = () => {
    const [data, setData] = useState([]);
    const [lastTest, setLastTest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('id'); // Mengambil userId dari localStorage
                if (!userId) {
                    console.error('User ID not found in localStorage');
                    return;
                }

                const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/getHasilByUserId/${userId}`);
                const result = response.data.map(item => ({
                    date: new Date(item.create_at).toLocaleDateString(),
                    total_skor: item.total_skor,
                    create_at: new Date(item.create_at).toLocaleDateString() // Menambahkan tanggal create_at
                }));
                setData(result);
                setLastTest(result[result.length - 1]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const getLastTestStatus = () => {
        if (!lastTest) return null;

        const { total_skor } = lastTest;
        if (total_skor > 12) {
            return {
                color: '#FF4545',
                text: 'Perkembangan mental anda belum baik, segera berkonsultasi atau bercerita tentang kondisi anda kepada orang terdekat 😓'
            };
        } else {
            return {
                color: '#2BA45D',
                text: 'Perkembangan mental anda sudah baik, terus pertahankan dan tetap jaga kesehatan 😊👍'
            };
        }
    };

    const lastTestStatus = getLastTestStatus();

    return (
        <div className="grafik-kuis-container-tiga">
            <Card title="Perkembangan Hasil Tes Quiz 1" className="grafik-card">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="create_at" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total_skor" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
            <Row gutter={16}>
                <Col span={12}>
                    <Card
                        title={<span style={{ color: 'white' }}><i className="fa-solid fa-chart-column"></i> Status Perkembangan Mental Anda</span>}
                        style={{ backgroundColor: lastTestStatus ? lastTestStatus.color : 'white', color: 'white' }}
                    >
                        {lastTestStatus ? lastTestStatus.text : 'Loading...'}
                    </Card>
                    <Card
                         title={<span style={{ color: 'white' }}><i className="fa-solid fa-circle-exclamation"></i> Peringatan Penting</span>}
                        style={{
                            marginTop: '16px',
                            backgroundColor: '#F46262',
                            color: 'white',
                            fontFamily: 'poppins',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                    >
                        <p>Fitur kuisioner ini dirancang untuk membantu mengidentifikasi kemungkinan seorang ibu mengalami depresi. Hasil dari kuisioner ini bukan merupakan diagnosis medis.</p>
                        <p>Jika Anda merasa tidak yakin dengan hasil kuisioner ini atau mengalami gejala yang parah, kami sangat menyarankan Anda untuk segera menghubungi ahli kesehatan atau rumah sakit terdekat ( NOMOR DARURAT 112 ).</p>
                        <p>Kesehatan Anda sangat penting, dan mendapatkan bantuan profesional adalah langkah terbaik yang bisa Anda ambil untuk memastikan kesejahteraan Anda dan bayi Anda.</p>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Informasi Tes">
                    Tes Depresi EPDS atau Edinburgh Postnatal Depression Scale adalah Tes dengan 10 item pertanyaan yang dikembangkan untuk mengidentifikasi wanita yang mengalami depresi pascapersalinan. Item skala yang digunakan sesuai dengan berbagai gejala depresi klinis, seperti perasaan bersalah, gangguan tidur, energi rendah, anhedonia, dan ide bunuh diri.
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default GrafikKuis_3;
