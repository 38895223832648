import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const RegisterAdmin = () => {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("tokenAdmin") && localStorage.getItem("userTypeAdmin") === "admin") {
      Swal.fire({
        title: "Anda sudah login",
        icon: "info",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate('/admin');
      });
    }
  }, [navigate]);

  const handleNamaChange = (event) => {
    setNama(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("nama", nama);
    formData.append("email", email);
    formData.append("password", password);

    axios
      .post("https://sipbibu.tifpsdku.com/backend/public/api/registeradmin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.message === 'Registered Successfully') {
          Swal.fire({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
          // Reset the form after successful registration
          setNama("");
          setEmail("");
          setPassword("");
          // Redirect to login page after successful registration
          navigate('/loginadmin');
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to register. Please try again later.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="register-container">
      <div className="cover">
        <h1>Register Admin</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nama"
            value={nama}
            onChange={handleNamaChange}
            required
            className="input-field"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
            className="input-field"
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="input-field"
            />
            <button
              type="button"
              onClick={handleShowPasswordToggle}
              className="show-password-btn"
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
          <button type="submit" className="register-btn">
            Register
          </button>
        </form>
        <p className="login-link">
          Already have an account? <a href="/loginadmin">Login</a>
        </p>
      </div>
    </div>
  );
};

export default RegisterAdmin;
