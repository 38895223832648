import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import './DataReply.css';

export const DataReply = () => {
    const { id } = useParams();
    const userId = localStorage.getItem('id');
    const idAdmin = localStorage.getItem('idAdmin');
    const [thread, setThread] = useState(null);
    const [adminDetails, setAdminDetails] = useState({});
    const [ibuDetails, setIbuDetails] = useState({});
    const [replies, setReplies] = useState([]);
    const [ibuNames, setIbuNames] = useState({});
    const [replyContent, setReplyContent] = useState('');
    const [replyImage, setReplyImage] = useState(null);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [replyTo, setReplyTo] = useState(null);
    const [categories, setCategories] = useState([]);
    const [repliesToReplies, setRepliesToReplies] = useState({});
    const [balasanReplyContent, setBalasanReplyContent] = useState('');
    const [balasanReplyImage, setBalasanReplyImage] = useState(null);
    const [openRepliesToReplies, setOpenRepliesToReplies] = useState({});

    const fetchRepliesByThread = useCallback(async () => {
        try {
            const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/reply/thread/${id}`);
            const replies = response.data;
    
            const ibuIds = replies.filter(reply => reply.create_by_ibu !== null).map(reply => reply.create_by_ibu);
            const uniqueIbuIds = [...new Set(ibuIds)];
    
            const ibuNamesResponse = await Promise.all(
                uniqueIbuIds.map(ibuId => axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${ibuId}`))
            );
    
            const newIbuNames = ibuNamesResponse.reduce((acc, res) => {
                acc[res.data.id] = { nama: res.data.nama, avatar: res.data.avatar };
                return acc;
            }, {});
    
            setReplies(replies);
            setIbuNames(newIbuNames);
    
            const newRepliesToReplies = {};
            await Promise.all(
                replies.map(async (reply) => {
                    try {
                        const balasanReplyResponse = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/replies/reply/${reply.id}`);
                        const balasanReplies = balasanReplyResponse.data;
    
                        const balasanIbuIds = balasanReplies.filter(br => br.create_by_ibu !== null).map(br => br.create_by_ibu);
                        const uniqueBalasanIbuIds = [...new Set(balasanIbuIds)];
    
                        const balasanIbuNamesResponse = await Promise.all(
                            uniqueBalasanIbuIds.map(ibuId => axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${ibuId}`))
                        );
    
                        const newBalasanIbuNames = balasanIbuNamesResponse.reduce((acc, res) => {
                            acc[res.data.id] = { nama: res.data.nama, avatar: res.data.avatar };
                            return acc;
                        }, {});
    
                        setIbuNames(prevIbuNames => ({
                            ...prevIbuNames,
                            ...newBalasanIbuNames,
                        }));
    
                        newRepliesToReplies[reply.id] = balasanReplies;
                    } catch (error) {
                        if (error.response && error.response.status === 404) {
                            console.warn(`No replies found for reply ID: ${reply.id}`);
                        } else {
                            console.error(`Error fetching replies for reply ID: ${reply.id}`, error);
                        }
                    }
                })
            );
    
            setRepliesToReplies(newRepliesToReplies);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    }, [id]);

    useEffect(() => {
        const fetchThread = async () => {
            try {
                const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/thread/${id}`);
                setThread(response.data);
                if (response.data.create_by_admin) {
                    fetchAdminDetails();
                } else {
                    fetchIbuDetails(response.data.create_by_ibu);
                }
            } catch (error) {
                console.error('Error fetching thread:', error);
            }
        };

        const fetchAdminDetails = async () => {
            try {
                const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-admin/${idAdmin}`);
                setAdminDetails(response.data);
            } catch (error) {
                console.error('Error fetching admin details:', error);
            }
        };

        const fetchIbuDetails = async (ibuId) => {
            try {
                const response = await axios.get(`https://sipbibu.tifpsdku.com/backend/public/data-ibu/${ibuId}`);
                setIbuDetails({ nama: response.data.nama, avatar: response.data.avatar });
            } catch (error) {
                console.error('Error fetching ibu details:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://sipbibu.tifpsdku.com/backend/public/kategori-thread');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchThread();
        fetchRepliesByThread();
        fetchCategories();
    }, [id, idAdmin, fetchRepliesByThread]);

    const handleSubmitReply = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('id_thread', id);
            formData.append('isi', replyContent);
            formData.append('create_by_admin', idAdmin);

            if (replyImage) {
                formData.append('gambar_reply', replyImage);
            }

            const response = await axios.post('https://sipbibu.tifpsdku.com/backend/public/reply', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setReplyContent('');
            setReplyImage(null);
            fetchRepliesByThread();
            setShowReplyForm(false);
            console.log('Reply created successfully:', response.data);
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    const handleReplyToReply = async (replyId) => {
        try {
            const formData = new FormData();
            formData.append('id_reply', replyId);
            formData.append('isi', balasanReplyContent);
            formData.append('create_by_admin', idAdmin);

            if (balasanReplyImage) {
                formData.append('gambar_balasan_reply', balasanReplyImage);
            }

            const response = await axios.post('https://sipbibu.tifpsdku.com/backend/public/replies', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setBalasanReplyContent('');
            setBalasanReplyImage(null);
            fetchRepliesByThread();
            setReplyTo(null);
            console.log('Balasan Reply created successfully:', response.data);
        } catch (error) {
            console.error('Error submitting balasan reply:', error);
        }
    };
    const handleDeleteReply = async (replyId) => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/reply/${replyId}`);
            fetchRepliesByThread();
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };
    
    const handleDeleteReplyToReply = async (balasanReplyId) => {
        try {
            await axios.delete(`https://sipbibu.tifpsdku.com/backend/public/replies/${balasanReplyId}`);
            fetchRepliesByThread();
        } catch (error) {
            console.error('Error deleting balasan reply:', error);
        }
    };
    
    const toggleOpenCloseRepliesToReplies = (replyId) => {
        setOpenRepliesToReplies(prevState => {
            return { ...prevState, [replyId]: !prevState[replyId] };
        });
    };
    
    if (!thread) {
        return <div>Loading...</div>;
    }
    
    const getCategoryNameById = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        return category ? category.kategori : 'Unknown';
    };
    
    return (
        <div className='thread-container-inside-admin'>
            
            <div className="reply-thread-detail-admin">
                <h3>★ Topik Utama</h3>
                <hr />
                <div className="ibu-reply-thread-detail-admin">
                    {thread.create_by_admin ? (
                      <h5><i class="fa-solid fa-user-tie"></i>Admin SIPBIBU</h5>
                    ) : (
                        <>
                            <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${ibuDetails.avatar}`} alt="Avatar Ibu" className="ibu-avatar-thread-top-admin" />
                            <h5>{ibuDetails.nama}</h5>
                        </>
                    )}
                </div>
                <h2>Pembuat Forum</h2>
                {thread.gambar_thread && (
                    <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${thread.gambar_thread}`} alt="Gambar Thread" className="thread-image-forum-admin" />
                )}
                <h4>Kategori | {getCategoryNameById(thread.id_kategori_thread)}</h4>
                <h1>{thread.judul}</h1>
                <p>{thread.isi}</p>
            </div>
    
            <div className="reply-thread-admin">
                <h3>Komentar Peserta <button onClick={() => setShowReplyForm(true)} className="reply-button-top-admin">
                    <h6>🗨️ Tambah Komentar</h6>
                </button></h3>
                <hr />
                {showReplyForm && (
                    <div className="modal-admin">
                        <div className="modal-content-admin">
                            <span className="close-admin" onClick={() => setShowReplyForm(false)}>&times;</span>
                            <form onSubmit={handleSubmitReply} className="reply-form-admin">
                                <textarea
                                    value={replyContent}
                                    onChange={(e) => setReplyContent(e.target.value)}
                                    placeholder="Tulis balasan Anda di sini..."
                                    required
                                />
                                <input
                                    type="file"
                                    onChange={(e) => setReplyImage(e.target.files[0])}
                                />
                                <button type="submit">Kirim Balasan</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
    
            <div className="replies-section-admin">
                {replies.map((reply) => (
                    <div key={reply.id} className="reply-admin">
                        <div className="reply-details-admin">
                            <div className="ibu-reply-details-admin">
                                {reply.create_by_admin ? (
                                    <h5>Admin</h5>
                                ) : (
                                    <>
                                        <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${ibuNames[reply.create_by_ibu]?.avatar}`} alt="Avatar Ibu" className="ibu-avatar-reply-admin" />
                                        <h5>{ibuNames[reply.create_by_ibu]?.nama}</h5>
                                    </>
                                )}
                            </div>
                            <div className='reply-details-isian-admin'>
                                <p>{reply.isi}</p>
                                {reply.gambar_reply && (
                                    <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${reply.gambar_reply}`} alt="Gambar Reply" className="reply-image-admin" />
                                )}
                            </div>
    
                            <span className="tooltip-container">
                            <span onClick={() => setReplyTo(reply.id)} className="reply-text-admin">
                                <i className="fa-regular fa-comment-dots"></i>
                            </span>
                            <span className="tooltip-text">Tulis Komentar</span>
                            </span>


                            <span className="tooltip-container">
                            {reply.create_by_admin !== userId && (
                                <span onClick={() => handleDeleteReply(reply.id)} className="delete-text-admin">
                                    <i className="fa-regular fa-trash-can"></i>
                                </span>
                            )}
                             <span className="tooltip-text">Hapus Komentar</span>
                            </span>
    
                            <div>
                                <span onClick={() => toggleOpenCloseRepliesToReplies(reply.id)} className="toggle-reply-text-admin">
                                    {openRepliesToReplies[reply.id] ? 'Tutup Balasan ▴' : 'Lihat Balasan ▾'}
                                </span>
                            </div>
    
                            {replyTo === reply.id && (
                                <div className="modal-admin">
                                    <div className="modal-content-admin">
                                        <span className="close" onClick={() => setReplyTo(null)}>&times;</span>
                                        <form onSubmit={(e) => { e.preventDefault(); handleReplyToReply(reply.id); }} className="reply-form-admin">
                                            <textarea
                                                value={balasanReplyContent}
                                                onChange={(e) => setBalasanReplyContent(e.target.value)}
                                                placeholder={`Balas ${reply.create_by_admin ? 'Admin' : ibuNames[reply.create_by_ibu]?.nama}`}
                                                required
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => setBalasanReplyImage(e.target.files[0])}
                                            />
                                            <button type="submit">Kirim Balasan</button>
                                        </form>
                                    </div>
                                </div>
                            )}
    
                        </div>
    
                        <div className="replies-to-reply-admin">
                            {openRepliesToReplies[reply.id] && (
                                <div className="balasan-replies-container-admin">
                                    {repliesToReplies[reply.id]?.map((balasanReply) => (
                                        <div key={balasanReply.id} className="balasan-reply-admin">
                                            <div className="ibu-balasan-reply-details-admin">
                                                {balasanReply.create_by_admin ? (
                                                    <h5>Admin</h5>
                                                ) : (
                                                    <>
                                                        <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${ibuNames[balasanReply.create_by_ibu]?.avatar}`} alt="Avatar Ibu" className="ibu-avatar-balasan-reply-admin" />
                                                        <h5>{ibuNames[balasanReply.create_by_ibu]?.nama}</h5>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <p>{balasanReply.isi}</p>
                                                {balasanReply.gambar_balasan_reply && (
                                                    <img src={`https://sipbibu.tifpsdku.com/backend/public/uploads/${balasanReply.gambar_balasan_reply}`} alt="Gambar Balasan Reply" className="balasan-reply-image-admin" />
                                                )}
                                            </div>
                                            {balasanReply.create_by_admin !== userId && (
                                                 <span className="tooltip-container">
                                                <span onClick={() => handleDeleteReplyToReply(balasanReply.id)} className="delete-button-admin">
                                                <i className="fa-regular fa-trash-can"></i>
                                                </span>
                                                    <span className="tooltip-text">Hapus</span>
                                                </span>
                                                )}
                                            </div>
                                            ))}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
        };
                                                
    export default DataReply;